body.node-type-m-dir-machine{
	.page-header {
		h1.title {
			display: none;
		}
	}
	#content{
		background-color: #ececec;
	}

	.node-m-dir-machine{
		.slider-section{
			position: relative;
			z-index: 1;
			.node-title-wrap{
				position: absolute;
				bottom: 361px;
				top: 100px;
				left: 0;
				width: 100%;
				z-index: 2;
				.field-name-field-formatted-title{
					border-left: 5px solid #fcc82b;
					padding: 0 0 0 19px;
					margin: 0 0 0 9px;
					color: #676447;
					font-family: $FuturaLight;
					font-size: 40px;
					font-weight: 400;
					line-height: 1;
					text-transform: uppercase;
					p{
						margin: 0;
					}
				}
			}
			
		}

		.yellow-engraving{
			/*min-height: 438px;*/
			/*background: url('../images/dir_machine/bg__yellow-square-engraving.png') center top no-repeat;*/
			background: url('../images/bg__yellow-block-new.png') center top  no-repeat;
			margin-top: -42px;
			z-index: 2;
			position: relative;
			margin-bottom: 43px;
			.field-name-body{
				font-size: 14px;
				font-weight: 300;
				line-height: 22px;
				text-align: center;
				padding: 40px 28px 0 28px;
			    min-height: 364px;
			    display: flex;
			    align-items: center;
				p{
					margin-bottom: 0;
					&:not(:last-child){
						margin-bottom: 22px;
					}
				}
			}
			&:after {
				background: url('../images/after-yellow.png') center top  no-repeat;
				width: 100%;
				height: 74px;
				display: block;
				bottom: -74px;
				left: 0px;
				position: absolute;
				content: '';
			}
		}

		.field-name-field-mdm-pass-through-block{
			/*margin-top: -117px;*/
			margin-bottom: 61px;
			p{
				margin: 0;
				&:not(:last-child){
					margin-bottom: 22px;
				}
			}
			img{
				width: 100%;
				height: auto;
			}
		}
	}

	.pane-node-field-mdm-outro-text{
		margin-bottom: 160px;
	}
	
	.field-name-field-mdm-outro-text{
		text-align: center;
		font-size: 14px;
		font-weight: 300;
		line-height: 22px;
		p{
			margin-bottom: 0px;
			&:not(:last-child){
				margin-bottom: 22px;
			}
		}
	}

}

.pane-v-dir-machine{
	margin-bottom: 39px;
	.pane-title{
		color: #676447;
		font-family: $FuturaLight;
		font-size: 30px;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		margin: 0 0 0 0;
		padding: 0 0 0 20px;
		border-left: 5px solid #fcc82b;
		p{
			margin: 0;
		}
	}
	.pane-content{
		margin-top: -64px;
	}
}

.view-v-dir-machine{
	.view-filters{
		margin-bottom: 60px;
		.form-item-sort-bef-combine{
			> label{
				display: none;
			}
		}

		.form-control.jq-selectbox{
			margin-left: auto;
		}
	}


	.view-content{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		//justify-content:space-between;
		justify-content: flex-start;
		.views-row{
			width: 356px;
			margin-bottom: 50px;
			&:nth-child(3n-2),
			&:nth-child(3n-1) {
				margin-right: 50px;
			}
			&:hover{
				box-shadow: 0 22px 22px rgba(0,0,0,0.12);
				.node-m-machine.node-teaser{
					.field-name-field-mm-img-preview{
						.field-item{
							img{
								box-shadow: none;
							}
						}
					}
					.field-name-field-formatted-title{
						border-color: #4cabca;
					}
					.node-detail{
						a{
							background-color: #4cabca;
						}
					}
				}
			}
			.node-m-machine.node-teaser{
				.field-name-field-mm-img-preview{
					.field-item{
						background: none!important;
						img{
							box-shadow: 0 12px 12px rgba(0, 0, 0, 0.07);
						}
					}
				}
				.bottom{
					height: 333px;
					background-color: white;
					display: flex;
					flex-direction:column;
					justify-content:flex-end;
					padding: 0px 0 0 49px;
				}
				.field-name-field-formatted-title{
					font-family: $FuturaLight;
					font-size: 15px;
					font-weight: 400;
					text-transform: uppercase;
					margin: 0 0 30px 0;
					padding: 0 0 0 10px;
					border-left: 5px solid #fcc82b;
					line-height: 1.2;
					min-height: 47px;
					display: flex;
					flex-direction: row;
					align-items: center;
					p{
						margin: 0;
					}
				}
				.field-name-field-mm-txt-preview{
					font-size: 14px;
					font-weight: 300;
					line-height: 22px;
					margin-bottom: 14px;
					p{
						margin: 0;
					}
				}
				.node-detail{
					text-align: right;
					font-size: 0;
					a{
						display: inline-block;
						width: 60px;
						height: 60px;
						background-color: #fcc82b;
						background-image: url('../images/arr_right.png');
						background-repeat: no-repeat;
						background-position: center center;
					}
				}
			}
		}
	}

	.item-list{
		ul.pager{
			margin: 40px 0 40px 0;
		}
	} 

}

@include respond-to('medium') {
	
	body.node-type-m-dir-machine{
		.slick--optionset--sc--single-only-dots {
			.slick__slider{
				.slick-list{
					.slick-slide{
						.slick__slide{
							img{
								height: 400px;
							}
						} 
					} 
				} 
				.slick-dots{
					top: 226px;
				}
			}
			.slick-media {
				img {
					width: 100%;
    				background-size: cover;
    				height: auto;
				}
			} 
		} 

		.node-m-dir-machine{
			.slider-section{
				.node-title-wrap{
					top: 40px;
					.field-name-field-formatted-title{
						font-size: 36px;
					}
				}
			} 
			.yellow-engraving{
				.field-name-body{
					/*padding-top: 120px;*/
				}
			} 
		}

	}
	.view-v-dir-machine{
		.view-filters{
			.form-control.jq-selectbox{
				/*margin-right: auto;*/
				width: 100%;
				max-width: 356px;
			}
		}
		.view-content{
			justify-content:space-between;
			.views-row{
				max-width: 356px;
				width: 100%;
				&:nth-child(3n-2),
				&:nth-child(3n-1) {
					margin-right: 0px;
				}
				.node-m-machine.node-teaser{
					.field-name-field-mm-img-preview{
						img{
							max-width: 100%;
							height: auto;
						}
					}
					.bottom{
						padding: 0px 19px 0 19px;
						min-height: 333px;
						height: initial;
					}
				}
			}
		}
	} 
	

}

@include respond-to('small'){


	body.node-type-m-dir-machine{
		.slick--optionset--sc--single-only-dots{
			.slick__slider{
				.slick-list{
					.slick-slide{
						.slick__slide{
							img{
								height: auto;
							}
						} 
					} 
				} 
				.slick-dots{
					position: static;
					left: 0;
					padding: 20px 0;
					text-align: center;
				}
			}
			.slick-media {
				img {
					width: 100%;
    				background-size: cover;
    				height: auto;
				}
			}  
		} 

		.node-m-dir-machine{
			.slider-section{
				.node-title-wrap{
					position: static;
					.field-name-field-formatted-title{
						font-size: 24px;
						text-align: center;
						padding: 20px 0 20px 0px;
						margin: 0 0 0 0px;
						border:0;
					}
				}
			} 
			.yellow-engraving{
				margin-top: 0px;
				margin-bottom: 0px;
				min-height: initial;
				background: #fcc82b;
				.field-name-body{
					padding: 40px 0px 40px 0px;
				}
			} 
			.field-name-field-mdm-pass-through-block{
				margin-top: 0px;
			}
		}
		
		.pane-node-field-mdm-outro-text{
			margin-bottom: 110px;
		}

	}
	
	.pane-v-dir-machine{
		.pane-title{
			padding: 0 0 0 10px;
			font-size: 24px;
		}
		.pane-content{
			margin-top: 45px;
		}
	} 

	.view-v-dir-machine{
		.view-filters{
			.form-control.jq-selectbox{
				margin-right: auto;
				width: 100%;
				max-width: 356px;
			}
		}
		.view-content{
			justify-content: center;
			.views-row{
				max-width: 356px;
				width: 100%;
				&:nth-child(3n-2),
				&:nth-child(3n-1) {
					margin-right: 0px;
				}
				.node-m-machine.node-teaser{
					.field-name-field-mm-img-preview{
						img{
							max-width: 100%;
							height: auto;
						}
					}
					.bottom{
						padding: 0px 0 0 19px;
						min-height: 333px;
						height: initial;
					}
				}
			}
		}
	} 

}