body.page-qa{

	#main{
		background: url('../images/node-grey-bg.png') center top no-repeat;
		padding-top: 137px;
	}
	
	.view-v-qa{
		margin-top: -47px;
		margin-bottom: 168px;
	}

}

#block-multiblock-12{
	a.ctools-use-modal{
		display: flex;
		flex-direction:row;
		justify-content: center;
		align-items:center;
		width: 193px;
		height: 60px;
		background-color: #d0d3d4;
		border-radius: 30px;
		color: #5d5747;
		font-family: $FuturaLight;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
	}
}

.view-v-qa {

	.view-filter-header{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-bottom: 86px;
		> *{
			&:not(:last-child){
				//margin-right: 30px;
			}
		}
	}
	.view-filters{
		.views-exposed-widgets{
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			> *{
				&:not(:last-child){
					margin-right: 30px;
				}
			}
		}
		.form-control.jq-selectbox{
			width: 356px;
		}
	}
	
	.view-content{
		.views-row{
			&:not(:last-child){
				margin-bottom: 86px;
			}
			.wrap{
				font-size: 16px;
				font-weight: 400;
				text-transform: uppercase;
				display: flex;
				flex-direction:row;
				align-items:flex-start;
				margin-bottom: 53px;
				.views-field-counter{
					border-right: 2px solid #fcc82b;
					padding-right: 5px;
					margin-right: 12px;
					color: #fcc82b;
					font-family: $FuturaBlack;
				}
				.views-field-field-mqa-q{
					color: #676447;
					font-family: $FuturaLight;
					p{
						margin: 0;
					}
				}
			}
			.views-field-body{
				font-size: 14px;
				font-weight: 300;
				line-height: 22px;
				background-color: white;
				border: 1px solid #fcc82b;
				box-shadow: 0 12px 12px rgba(0, 0, 0, 0.05);
				padding: 33px 54px 33px 54px;
				position: relative;
				&::before{
					content: '';
					position: absolute;
					left: 29px;
					top: -31px;
					width: 30px;
					height: 31px;
					background: url('../images/qa/angle.png') no-repeat;
				}
				p{
					margin-bottom: 0px;
					&:not(:last-child){
						margin-bottom: 23px;
					}
				}
			}
		}
	}

	.item-list{
		ul.pager{
			margin: 90px 0 93px 0;
		}
	} 
	
	.view-footer{
		#block-multiblock-12{
			a.ctools-use-modal{
				margin-left: auto;
				margin-right: auto;
				margin-top: 40px;
			}
		} 
	}

}

@include respond-to('medium'){
	body.page-qa{
		#main{
			padding-top: 107px;
		}
		.view-v-qa{
			margin-top: 57px;
			.view-filter-header{
				justify-content: center;
			}
		}
	} 

	.view-v-qa{
		.view-content{
			.views-row{
				.views-field-body{
					padding: 33px 33px 33px 33px;
				}
			} 
		} 
	} 
}

@include respond-to('small'){
	body.page-qa{
		#main{
			padding-top: 80px;
		}
		.view-v-qa{
			margin-top: 57px;
			margin-bottom: 118px;
		}
	} 

	.view-v-qa{
		.view-filter-header{
			justify-content: flex-start;
			flex-direction:column;
			align-items:center;
			margin-bottom: 58px;
		}
		.view-filters{
			width: 100%;
			margin-bottom: 20px;
			.views-exposed-widgets{
				justify-content: center;
				>*:not(:last-child){
					margin-right: 0px;
					width: 100%;
				}
			}
			.form-control.jq-selectbox{
				width: 100%;
				max-width: 356px;
			}
		} 
		.view-content{
			.views-row{
				&:not(:last-child){
					margin-bottom: 50px;
				}
				.wrap{
					font-size: 15px;
					flex-direction: column;
					align-items: center;
					text-align: center;
					.views-field-counter{
						padding-right: 5px;
						padding-left: 5px;
						margin-right: 0;
						padding-bottom: 5px;
						margin-bottom: 12px;
						border-right: 0pc;
						border-bottom: 2px solid #fcc82b;
					}
				}
				.views-field-body{
					padding: 15px 10px 15px 10px;
					&::before{
						left: 0;
						right: 0;
						margin: 0 auto;
					}
				}
			} 
		} 
		.item-list{
			ul.pager{
				margin: 50px 0 53px 0;
			}
		} 
	}
}