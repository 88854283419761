.form-control.jq-selectbox {
	width: 356px;
	height: 60px;
	display: block!important;
	.jq-selectbox__select {
    	padding: 0px 0px 0 30px;
		position: relative;
		text-align: left;
		width: 100%;
		height: 100%;
		box-shadow: none;
		background-color: white;
		display: flex;
		flex-direction:row;
		align-items:center;
		.jq-selectbox__select-text {
			color: black;
			font-family: $FuturaLight;
			font-size: 15px;
			font-weight: 400;
			line-height: 1;
		}
	}
	.jq-selectbox__trigger {
		right: 0px;
		top: 0px;
		bottom: 0;
		margin: auto 0;
		width: 60px;
		height: 60px;
		background-color: #fcc82b;
		background-image: url('../images/arr_bottom.png');
		background-repeat: no-repeat;
		background-position: center center;
	}
	.jq-selectbox__dropdown {
		background-color: rgba(255, 255, 255, 0.95);
		box-shadow: 0 22px 44px rgba(0, 0, 0, 0.12);
		left: 0;
		padding: 29px 30px 19px 30px;
	}
	ul {
		width: 100%!important;
		margin: 0;
		padding: 0 0 0 0;
		list-style: none;
		li {
			padding-left: 0px;
			padding-bottom: 18px;
			position: relative;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.28;
			color: #384359;
			&:hover {
				color: #eba327;
			}
			&.disabled{
				display: none;
			}
		}
	}

	&:hover,
	&.opened{

	}
	&.opened{
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 50%;
			width: 100%;
			background-color: rgba(255, 255, 255, 0.95);
		}
	}
}

.form-control.jq-selectbox .jq-selectbox__dropdown {
	top: 60px !important;
    bottom: auto!important;
}