.node-type-m-our-innova {
  .page-header {
    h1.title {
      display: none;
    }
  }
}
.slick--optionset--sc--single-only-dots{
  .slick__slider{
    .slick-list{
      .slick-slide{
        .slick__slide{
          img{
            object-fit: cover;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .slick-dots{
      font-size: 0;
      margin: 0 0 0 0;
      padding: 0 0 0 34px;
      position: absolute;
      top: 287px;
      left: calc( (100% - #{$container-lg}) / 2);
      @include respond-to('medium'){
        left: calc( (100% - #{$container-md}) / 2);
      }
      @include respond-to('small'){
        left: 10px;
      }
      li{
        list-style: none;
        display: inline-block;
        cursor: pointer;
        border-radius: 100%;
        width: 9px;
        height: 9px;
        background-color: #fcc82b;
        font-size: 0;
        text-indent: -9999px;
        &:not(:last-child){
          margin-right: 11px;
        }
        &.slick-active{
          background-color: #676447;
        }
      }
    }
  }
}
#slick-node-154-m-our-innova-field-moi-slider-1 {
  .slick-dots {
    top: 370px;
    @include respond-to('medium') {
      top: 226px;
    }
  }
}

body.node-type-m-our-innova{

  .slider-section{
    position: relative;
    z-index: 1;
    .node-title-wrap{
      position: absolute;
      bottom: 371px;
      left: 0;
      width: 100%;
      z-index: 2;
      .field-name-field-formatted-title{
        border-left: 5px solid #fcc82b;
        padding: 0 0 0 19px;
        margin: 0 0 0 9px;
        color: #676447;
        font-family: $FuturaLight;
        font-size: 40px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        p{
          margin: 0;
        }
      }
    }
    
  }

  .yellow-engraving{
    min-height: 438px;
    background: url('../images/our_innova/bg__yellow-square-engraving.png') center top no-repeat;
    margin-top: -77px;
    z-index: 2;
    position: relative;
    margin-bottom: 43px;
    .field-name-body{
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      text-align: center;
      padding: 150px 28px 0 28px;
      padding: 40px 28px 0 28px;
      min-height: 364px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  
  .paragraphs-item-pb--our-awards{
    margin-bottom: 100px;
  }
  
  .paragraphs-item-pb--become-partner{
    .field-name-field-link{
      a{
        width: 230px;
      }
    } 
  } 

}

.field-name-field-title-decor-big{
  color: #5d5747;
  font-family: $FuturaLight;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.24;
  p{
    margin: 0;
  }
}
.paragraphs-item-pb--innova-operation {
  .field-name-field-title-decor-big {
    text-align: center;
    .field-item {
      position: relative;
      color: #5d5747;
      font-family: $FuturaLight;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.24;
      padding: 0 25px;
      @include respond-to('small') {
        padding: 0 21px;
        margin: 0 10px;
      }
      &::before {
        content: '';
        position: absolute;
        top: -3px;
        bottom: 0;
        margin: auto 0;
        left: 0px;
        width: 12px;
        height: 12px;
        background: url('../images/title-decor.png') no-repeat;
      }
      &::after {
        content: '';
        position: absolute;
        top: -3px;
        bottom: 0;
        margin: auto 0;
        right: 0px;
        width: 12px;
        height: 12px;
        background: url('../images/title-decor.png') no-repeat;
      }
    }
  }
}

.bg-grey{
  background-color: #ececec;
  @include clearfix();
  padding: 114px 0 84px 0;
}

.paragraphs-item-pb--icon-text-template{
  .field-name-field-image{
    .field-item{
      background: none!important;
      img{
        opacity: 1;
      }
    }
  }
  .field-name-field-description{
    
  }
}

.paragraphs-item-pb--innova-operation{
  margin-bottom: 108px;
  .group-wrap{
    > .field-name-field-title-decor-big{
      margin-bottom: 65px;
    }
    > .paragraphs-items-field-pbio-operation{
      display: flex;
      flex-direction:row;
      .paragraphs-item-pb--icon-text-template{
        width: 25%;
        text-align: center;
        .field-name-field-image{
          min-height: 93px;
        }
        .field-name-field-description{
          font-size: 14px;
          font-weight: 300;
          line-height: 1.24;
        }
      }
    }
  }
}

.paragraphs-item-pb--innova-schema{
  &:not(:last-child){
    margin-bottom: 80px;
  }
  .field-name-field-title-decor{
    margin-bottom: 33px;
    .field-item{
      font-size: 20px;
    }
  }
  .paragraphs-items-field-pbis-schemas{
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    max-width: 1545px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    .paragraphs-item-pb--icon-text-template{
      text-align: center;
      position: relative;
      margin-bottom: 20px;
      &:not(:last-child){
        &::after{
          content: '';
          width: 14px;
          height: 22px;
          position: absolute;
          top: 75px;
          right: -31px;
          background: url('../images/our_innova/schema_arrow.png') no-repeat;
        }
        margin-right: 45px;
      }
      .field-name-field-image{
        margin-bottom: 17px;
      }
      .field-name-field-description{
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        line-height: 1.3;
      }
    }
  }
}

.grey-union-1{
  margin-bottom: 118px;
}

.paragraphs-item-pb--innova-decision{
  margin-bottom: 87px;
  .group-wrap{
    > .field-name-field-title-decor-big{
      text-align: left;
      margin: 0 0 39px 9px;
      padding: 0 0 0 29px;
      border-left: 5px solid #fcc82b;
      .field-item{
        >*:first-child{
          &::before{
            content: none;
          }
        }
        >*:last-child{
          &::after{
            content: none;
          }
        }
      }
    }
    > .paragraphs-items-field-pbid-decisions{
      .paragraphs-item-pb--icon-text-template{
        border-bottom: 2px solid #fcc82b;
        margin-bottom: 30px;
        > .content{
          display: flex;
          align-items:center;
        }
        .field-name-field-image{
          width: 200px;
        }
        .field-name-field-description{
          font-size: 14px;
          font-weight: 300;
          line-height: 1.24;
          margin-top: 6px;
          width: calc(100% - 252px);
        }
        &:nth-child(odd){
          > .content{
            flex-direction:row;
          }
          .field-name-field-image{
            margin: 0 52px 0 0;
          }
        }
        &:nth-child(even){
          > .content{
            flex-direction:row-reverse;
          }
          .field-name-field-image{
            margin: 0 0px 0 52px;
          }
          .field-name-field-description{
            margin-right: auto;
          }
        }
      }
    }
  }
  &.blue{
    .group-wrap{
      > .field-name-field-title-decor-big{
        border-color: #4cabca;
      }
      > .paragraphs-items-field-pbid-decisions{
        .paragraphs-item-pb--icon-text-template{
          border-color: #4cabca;
        }
      }
    }
  }
}

.innova_benefit{
  margin-bottom: 115px;
  .field-name-field-title-decor{
    margin-bottom: 47px;
  }
  .benefits{
    display: flex;
    flex-direction:row;
    justify-content:center;
    padding-top: 58px;
    background: url('../images/our_innova/bg__benefits.png') center top no-repeat;
    .benefit{
      text-align: center;
      width: 310px;
      &:not(:last-child){
        margin-right: 34px;
      }
      .b__img{
        margin-bottom: 25px;
      }
      .b__title{
        color: #767d39;
        font-family: $FuturaLight;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1.28;
        margin-bottom: 12px;
      }
      .b__body{
        font-size: 14px;
        font-weight: 300;
        line-height: 1.28;
      }
      &.benefit-2{
        margin-top: 354px;
      }
    }
  }
}

@include respond-to('medium'){
  .slick--optionset--sc--single-only-dots{
    .slick__slider{
      .slick-list{
        .slick-slide{
          .slick__slide{
            img{
              height: 400px;
            }
          } 
        } 
      } 
      .slick-dots{
        top: 226px;
      }
    } 
  } 

  body.node-type-m-our-innova{
    .node-m-our-innova{
      .slider-section{
        .node-title-wrap{
          bottom: 200px;
          .field-name-field-formatted-title{
            font-size: 36px;
          }
        }
      } 
      .yellow-engraving{
        .field-name-body{
          padding-top: 45px;
        }
      } 
    }
  }
  
  .paragraphs-item-pb--innova-operation{
    margin-bottom: 83px;
    .group-wrap{
      >.field-name-field-title-decor-big{
        p{
          br{
            display: none;
          }
        }
      }

      >.paragraphs-items-field-pbio-operation{
        flex-wrap:wrap;
        .paragraphs-item-pb--icon-text-template{
          width: 50%;
          margin-bottom: 25px;
        }
      } 
    }
  } 
  
  .paragraphs-item-pb--innova-schema{
    &:not(:last-child){
      margin-bottom: 60px;
    }
    .field-name-field-title-decor{
      .field-item{
        font-size: 18px;
        margin: 0 40px;
      }
    }
    .paragraphs-items-field-pbis-schemas{
      .paragraphs-item-pb--icon-text-template{
        width: calc((100% / 3) - 45px);
        .field-name-field-image{
          img{
            height: auto;
          }
        }
      }
    } 
  } 

  .paragraphs-item-pb--innova-decision{
    margin-bottom: 50px;
    .group-wrap{
      > .field-name-field-title-decor-big{
        font-size: 18px;
      }
      > .paragraphs-items-field-pbid-decisions{
        .paragraphs-item-pb--icon-text-template{
          .field-name-field-description{
            
            width: calc(100% - 225px);
          }
          &:nth-child(odd){
            .field-name-field-image{
              margin: 0 25px 0 0;
            }
          }
          &:nth-child(even){
            .field-name-field-image{
              margin: 0 0px 0 25px;
            }
          }
        }
      }
    }
  } 
  
  /*.innova_benefit{
    .benefits{
      .benefit{
        width: calc(100% /3);
        &:not(:last-child){
          margin-right: 17px;
        }
        .b__title{
          br{
            display: none;
          }
        }
        .b__body{
          br{
            display: none;
          }
        }
      }
    } 
  }*/ 

  .innova_benefit{
    margin-bottom: 70px;
    .field-name-field-title-decor{
      margin-bottom: 29px;
      .field-item{
        font-size: 30px;
        padding: 0;
        line-height: 1;
        &::before{
          position: relative;
          top: -16px;
          content: '.';
            display: inline-block;
            font-size: 0;
            text-indent: -9999px;
            margin-right: 8px;
        }
        &::after{
          position: relative;
          top: -16px;
          content: '.';
            display: inline-block;
            font-size: 0;
            text-indent: -9999px;
            margin-left: 8px;
        }
      }
    }
    .benefits{
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; 
      background: url(../images/our_innova/bg__benefits-mob.png) center top no-repeat;
      padding-top: 280px;
      .benefit{
        width: 100%;
        padding-top: 72px;
        background: url(../images/our_innova/benefits-dots.png) center top no-repeat;
        &:not(:last-child){
          margin: 0 0 17px 0;
        }
        .b__title{
          br{
            
          }
        }
        .b__body{
          br{
            display: none;
          }
        }
      }
    } 
  } 

}

@include respond-to('small'){

  .slick--optionset--sc--single-only-dots{
    .slick__slider{
      .slick-list{
        .slick-slide{
          .slick__slide{
            img{
              height: auto;
            }
          } 
        } 
      } 
      .slick-dots{
        position: static;
        left: 0;
        padding: 20px 0;
        text-align: center;
      }
    } 
  } 
  
  body.node-type-m-our-innova{
    .node-m-our-innova{
      .slider-section{
        .node-title-wrap{
          position: static;
          .field-name-field-formatted-title{
            font-size: 24px;
            text-align: center;
            padding: 20px 0 20px 0px;
            margin: 0 0 0 0px;
            border:0;
          }
        }
      } 
      .yellow-engraving{
        margin-top: 0px;
        min-height: initial;
        background: #fcc82b;
        margin-bottom: 60px;
        .field-name-body{
          padding: 40px 0px 40px 0px;
        }
      } 
      .field-name-field-mdm-pass-through-block{
        margin-top: 0px;
      }
    }

    .paragraphs-item-pb--become-partner{
      margin-bottom: 50px;
      .field-name-field-description{
        br{
          display: none;
        }
      }
    } 

    .paragraphs-item-pb--our-awards{
      margin-bottom: 70px;
    }

  }
  
  .paragraphs-item-pb--innova-operation{
    margin-bottom: 50px;
    .group-wrap{
      >.field-name-field-title-decor-big{
        font-size: 17px;
        margin-bottom: 35px;
        p{
          br{
            display: none;
          }
        }
      }

      >.paragraphs-items-field-pbio-operation{
        flex-wrap:wrap;
        .paragraphs-item-pb--icon-text-template{
          width: 100%;
          margin-bottom: 25px;
        }
      } 
    }
  } 

  .paragraphs-item-pb--innova-schema{
    &:not(:last-child){
      margin-bottom: 60px;
    }
    .field-name-field-title-decor{
      .field-item{
        font-size: 16px;
      }
    }
    .paragraphs-items-field-pbis-schemas{
      .paragraphs-item-pb--icon-text-template{
        width: calc(100%  - 45px);
        .field-name-field-image{
          img{
            height: auto;
          }
        }
      }
    } 
  } 

  .paragraphs-item-pb--innova-decision{
    margin-bottom: 30px;
    .group-wrap{
      > .field-name-field-title-decor-big{
        font-size: 16px;
        margin: 0 0 39px 0px;
        padding: 0 0 0 15px;
      }
      > .paragraphs-items-field-pbid-decisions{
        .paragraphs-item-pb--icon-text-template{
          padding-bottom: 5px;
          > .content{
            flex-direction: column!important;
            justify-content: flex-start;
            align-items: center;
          }
          .field-name-field-image{
            margin: 0 0 20px 0!important;
            width: initial;
          }
          .field-name-field-description{
            width: 100%;
          }
        }
      }
    }
  } 

  .bg-grey{
    padding: 60px 0 60px 0;
  }

  .grey-union-1{
    margin-bottom: 70px;
  }

  .innova_benefit{
    margin-bottom: 70px;
    .field-name-field-title-decor{
      margin-bottom: 29px;
      .field-item{
        font-size: 30px;
        padding: 0;
        line-height: 1;
        &::before{
          position: relative;
          top: -16px;
          content: '.';
            display: inline-block;
            font-size: 0;
            text-indent: -9999px;
            margin-right: 8px;
        }
        &::after{
          position: relative;
          top: -16px;
          content: '.';
            display: inline-block;
            font-size: 0;
            text-indent: -9999px;
            margin-left: 8px;
        }
      }
    }
    .benefits{
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; 
      background: url(../images/our_innova/bg__benefits-mob.png) center top no-repeat;
      padding-top: 280px;
      .benefit{
        width: 100%;
        padding-top: 72px;
        background: url(../images/our_innova/benefits-dots.png) center top no-repeat;
        &:not(:last-child){
          margin: 0 0 17px 0;
        }
        .b__title{
          br{
            
          }
        }
        .b__body{
          br{
            display: none;
          }
        }
      }
    } 
  } 

}