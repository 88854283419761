body.page-reviews{

	#main{
		background: url('../images/node-grey-bg.png') center top no-repeat;
		padding-top: 137px;
	}
	
	.view-v-reviews{
		margin-top: -47px;
		margin-bottom: 129px;
	}

}

.view-v-reviews{

	.view-filter-header{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-bottom: 90px;
		> *{
			&:not(:last-child){
				//margin-right: 30px;
			}
		}
	}
	.view-filters{
		.views-exposed-widgets{
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			> *{
				&:not(:last-child){
					margin-right: 30px;
				}
			}
		}
		.form-control.jq-selectbox{
			width: 266px;
		}
	}

	.view-content{
		.views-row{
			margin-bottom: 90px;
			&:nth-child(even){
				.node-m-reviews{
					.circle{
						background-color: #facb3e;
					}
				}
			}
			&:nth-child(odd){
				.node-m-reviews{
					.circle{
						background-color: #d0d3d4;
					}
				}
			}
			.node-m-reviews{
				display: flex;
				flex-direction:row;
				.circle{
					width: 214px;
					height: 214px;
					border-radius: 100%;
					margin-right: 56px;
					background-repeat: no-repeat;
					background-image: url('../images/circle-reviews-pattern.png');
					display: flex;
					flex-direction:column;
					align-items:center;
					justify-content: center;
					text-align: center;
				}
				.right-group{
					width: calc(100% - 270px);
					display: flex;
					flex-direction:row;
					justify-content:flex-start;
					align-items:flex-start;
				}
				.node-created{
					color: #676447;
					font-family: $FuturaLight;
					font-size: 13px;
					font-weight: 400;
					text-transform: uppercase;
					width: 98px;
					background-color: white;
					border-radius: 8px;
					line-height: 1;
					height: 15px;
					padding-top: 2px;
					margin-bottom: 18px;
				}
				.field-name-field-mr-author{
					color: #676447;
					font-family: $FuturaLight;
					font-size: 16px;
					font-weight: 400;
					text-transform: uppercase;
					line-height: 1.2;
				}
				.field-name-field-image{
					border: 1px solid #fcc82b;
					box-shadow: 0 12px 12px rgba(0, 0, 0, 0.05);
					width: 216px;
					margin-right: 56px;
					a{
						display: block;
						position: relative;
						&:hover{
							&::before{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: rgba(252, 200, 43, 0.9);
								background-image: url('../images/search.png');
								background-repeat: no-repeat;
								background-position: center center;
							}
						}
					}
				}

				.field-name-body{
					width: 100%;
					background-color: white;
					border: 1px solid #fcc82b;
					box-shadow: 0 12px 12px rgba(0, 0, 0, 0.05);
					font-size: 14px;
					font-weight: 300;
					line-height: 22px;
					padding: 41px 61px 29px 70px;
					position: relative;
					&::before{
						content: '';
						position: absolute;
						top: 89px;
						left: -27px;
						width: 27px;
						height: 26px;
						background-image: url('../images/reviews-body-angle.png');
						background-repeat: no-repeat;
					}
				}

				.video{
					width: 100%;
					border: 1px solid #fcc82b;
					font-size: 0;
					position: relative;
					.video__overlay{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						font-size: 0;
						text-indent:-9999px;
						background-color: rgba(250, 203, 62, 0.5);
						background-image: url('../images/icon__ytube.png');
						background-repeat: no-repeat;
						background-position: center top 36px;
						z-index: 1;
					}
					a.colorbox-load-video,
					a.colorbox-load{
						display: flex;
						flex-direction:row;
						justify-content:center;
						align-items:center;
						text-align: center;
						position: absolute;
						bottom: 36px;
						left: 0;
						right: 0;
						margin: 0 auto;
						width: 170px;
						height: 40px;
						background-color: rgba(255,255,255,.9);
						border-radius: 20px;
						color: #676447;
						font-family: $FuturaLight;
						font-size: 13px;
						font-weight: 400;
						text-transform: uppercase;
						z-index: 2;
						cursor: pointer;
					}
					iframe{
						width: 100%;
						height: 212px;
					}
				}

			}
		}
	}
	
	#block-multiblock-4{
		a.ctools-use-modal{
			display: inline-flex;
			flex-direction:row;
			justify-content:center;
			align-items:center;
			width: 193px;
			height: 60px;
			background-color: #d0d3d4;
			border-radius: 30px;
			color: #5d5747;
			font-family: $FuturaLight;
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
		}
	}

	.view-footer{
		text-align: center;
		margin-bottom: 58px;
	}

}

@include respond-to('medium'){
	body.page-reviews{
		
		#main{
			padding-top: 107px;
		}

		.view-v-reviews{
			margin-top: 47px;
		}
	} 

	.view-v-reviews{
		.view-filter-header{
			margin-bottom: 47px;
			justify-content: center;
			flex-wrap: wrap;
		}
		.view-filters{
			margin-bottom: 20px;
		}
		.view-content{
			.views-row{
				margin-bottom: 75px;
				.node-m-reviews{
					flex-direction: column;
					align-items: center;
					.circle{
						margin-right: 0px;
						margin-bottom: 56px;
					}
					.right-group{
						width: 100%;
					}
					.field-name-body{
						padding: 41px 41px 29px 50px;
						&::before{
							top: -31px;
						    left: 0px;
						    right: 0px;
						    margin: 0 auto;
						    width: 30px;
						    height: 31px;
						    background-image: url(../images/reviews-body-angle-mob.png);
						}
					}
					.field-name-field-image{
						margin-right: 30px;
					}
				}
			}
		}
	} 
}

@include respond-to('small'){
	
	body.page-reviews{
		
		#main{
			padding-top: 90px;
		}

		.view-v-reviews{
			margin-top: 47px;
			margin-bottom: 99px;
		}
	} 

	.view-v-reviews{
		.view-filter-header{
			margin-bottom: 47px;
			justify-content: center;
			flex-wrap: wrap;
		}
		.view-filters{
			margin-bottom: 20px;
			.views-exposed-widgets{
				flex-direction:column;
				justify-content: flex-start;
				align-items:center;
				>*:not(:last-child){
					margin-right: 0px;
					margin-bottom: 20px;
				}
			}
		}
		.view-content{
			.views-row{
				.node-m-reviews{
					flex-direction:column;
					align-items: center;
					.circle{
						margin-right: 0px;
					}
					.right-group{
						width: 100%;
						flex-direction:column;
						align-items:center;
					}
					.field-name-body{
						padding: 42px 15px 34px 15px;
						margin-top: 47px;
						text-align: center;
						&::before{
							top: -27px;
						    left: 0px;
						    right: 0px;
						    margin: 0 auto;
						    width: 26px;
						    height: 27px;
						    background-image: url(../images/reviews-body-angle-mob.png);
						}
					}
					.video{
						margin-top: 20px;
					}
					.field-name-field-image{
						margin-right: 0px;
						margin-top: 20px;
					}
				}
			}
		}
	} 

}