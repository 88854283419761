body.node-type-m-sparepart {
  .page-header {
    h1.title {
      display: none;
    }
  }
  .node-m-sparepart {
    .big-image{
      position: relative;
      background: #eeeeee;
      .field-name-field-mm-mobile-img{
        display: none;
        .field-item{
          background-position: center top;
        }
      }
      @include respond-to('small'){
        .field-name-field-mm-mobile-img{
          display: block;
        }
        .field-name-field-mm-mobile-img + .field-name-field-mm-big-img{
          display: none;
        }
      }
      .field-name-field-mm-big-img{
        .field-item{
          background-position: center top;
        }
      }
      > .bi__container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
      .node-title-wrap{
        margin: 102px 0 0 9px;
        padding: 0 0 0 19px;
        border-left: 5px solid #fcc82b;
      }
      .field-name-field-formatted-title{
        color: #676447;
        font-family: $FuturaLight;
        font-size: 30px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
      }
      .field-name-field-mm-after-title{
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        margin: 29px 0 0 35px;
      }
      .field-name-field-mm-gallery{
        margin: 38px 0 0 34px;
        > .field-items{
          display: flex;
          flex-direction:row;
          flex-wrap:wrap;
          justify-content:space-between;
          width: 244px;
          > .field-item{
            margin-bottom: 10px;
            a{
              display: block;
              position: relative;
              &:hover{
                &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(252, 200, 43, 0.9);
                  background-image: url('../images/search.png');
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              }
              img{
                box-shadow: 0 12px 12px rgba(0, 0, 0, 0.07);
              }
            }
          }
        }
      }
    }

    .yellow-block{
      /*min-height: 473px;*/
      /*background: url('../images/machine/bg__yellow-block.png') center top / cover  no-repeat;*/
      background: url('../images/bg__yellow-block-new.png') center top  no-repeat;
      padding-bottom: 74px;
      margin-top: -70px;
      z-index: 2;
      position: relative;
      padding-top: 135px;
      &:after {
        background: url('../images/after-yellow.png') center top  no-repeat;
        width: 100%;
        height: 74px;
        display: block;
        bottom: -74px;
        left: 0px;
        position: absolute;
        content: '';
      }
      .field-name-body{
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        margin-bottom: 34px;
        p{
          margin: 0;
          &:not(:first-of-type){
            margin-top: 22px;
          }
        }
        ul{
          li{
            font-weight: 300;
            &::before{
              background-color: white;
            }
          }
        } 
      }

      .links{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        > *{
          &:not(:last-child){
            margin-right: 30px;
          }
        }
      }

      .field-name-field-mm-pdf{
        .file{
          .file-icon,
          .file-size{
            display: none;
          }
          a{
            display: flex;
            flex-direction:row;
            justify-content:center;
            align-items:center;
            width: 256px;
            height: 50px;
            background-color: white;
            border-radius: 25px;
            box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
            color: #676447;
            font-family: $FuturaLight;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
          }
        }
      }

      .field-name-field-mm-3d-model{
        a{
          display: flex;
          flex-direction:row;
          justify-content:center;
          align-items:center;
          width: 256px;
          height: 50px;
          background-color: white;
          border-radius: 25px;
          box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
          color: #676447;
          font-family: $FuturaLight;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      #block-multiblock-1{
        a.ctools-use-modal{
          display: flex;
          flex-direction:row;
          justify-content:center;
          align-items:center;
          width: 256px;
          height: 50px;
          background-color: #4cabca;
          border-radius: 25px;
          box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
          color: white;
          font-family: $FuturaLight;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

    }
    
    .paragraphs-items-field-mm-paragraphs{
      //margin-top: -74px;
    }

  }
  .paragraphs-item-pb--packages-set {
    margin-bottom: 0px;
  }
  .bg-grey {
    //margin-bottom: 112px;
    padding: 114px 0 0px 0;
  }

  .paragraphs-item-pb--our-awards{
    margin-bottom: 68px;
  }
  .paragraphs-item-pb--tabs-set {
    margin-top: 118px;
  }
}

@include respond-to('medium') {
  body.node-type-m-sparepart {
    .node-m-sparepart {
      .yellow-block {
        min-height: initial;
        padding-top: 70px;
        padding-bottom: 80px;
        margin-top: 0px;
        background: url(../images/machine/bg__yellow-block-mob.jpg) center center repeat;
        .links {
          justify-content: space-around;
          > * {
            &:not(:last-child) {
              margin-bottom: 25px;
            }
          }
        }
      }
    } 
    .bg-grey {
      padding: 80px 0 84px 0;
    }
    .paragraphs-item-pb--our-awards {
      margin-bottom: 50px;
    }
  } 
} 

@include respond-to('small') {
  body.node-type-m-sparepart {
    .node-m-sparepart {
      .big-image {
        padding-bottom: 49px;
        > .bi__container{
          position: relative;
        }
        .node-title-wrap{
          margin: 33px 0 0 0px;
          padding: 0 0 0 10px;
        }
        .field-name-field-formatted-title {
          font-size: 22px;
          line-height: 1.2;
        }
        .field-name-field-mm-after-title {
          margin: 27px 0 0 0px;
        }
        .field-name-field-mm-gallery {
          margin: 35px 0 0 0px;
          .field-items{
            margin-left: auto;
            margin-right: auto;
          }
        }
        .field-name-field-mm-big-img {
          .field-item {
            background:none!important;
            img {
              opacity: 1;
              height: auto;
            }
          }
        }
      }
      .yellow-block {
        min-height: initial;
        padding-top: 72px;
        padding-bottom: 80px;
        margin-top: 0px;
        background: url(../images/machine/bg__yellow-block-mob.jpg) center center repeat;
        .links {
          flex-direction: column;
          justify-content: flex-start;
          align-items:center;
          > * {
            margin-right: 0px;
            &:not(:last-child) {
              margin-right: 0px;
              margin-bottom: 20px;
            }
          }
        }
        .field-name-body {
          text-align: center;
          margin-bottom: 54px;
        }
      }
    } 
    .bg-grey {
      padding: 80px 0 84px 0;
    }
    .paragraphs-item-pb--our-awards {
      margin-bottom: 50px;
    }
  } 
}










