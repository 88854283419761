body.node-type-m-terms-dealers{
  .page-header {
    h1.title {
      display: none;
    }
  }
  #main-wrapper{
    //margin-top: -30px;
  }
  
  #breadcrumb{
    position: relative;
    z-index: 10;
  }

  .node-m-terms-dealers{

    .big-image{
      position: relative;
      .field-name-field-mtd-big-img{
        .field-item{
          background-position: center top;
        }
      }
      > .bi__container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
      .node-title{
        margin: 304px 0 0 0px;
        padding: 0 0 0 0px;
        color: white;
        font-family: $FuturaLight;
        font-size: 40px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          bottom: -15px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 209px;
          height: 5px;
          background-color: #fcc82b;
        }
      }
    }
    
    .yellow-block{
      /*min-height: 473px;*/
      /*background: url('../images/dealers/bg__yellow-block.png') center top  no-repeat;*/
      background: url('../images/bg__yellow-block-new.png') center top  no-repeat;
      margin-top: -43px;
      z-index: 2;
      position: relative;
      padding-top: 121px;
      &:after {
        background: url('../images/after-yellow.png') center top  no-repeat;
        width: 100%;
        height: 74px;
        display: block;
        bottom: -74px;
        left: 0px;
        position: absolute;
        content: '';
      }
      .field-name-body{
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        margin-bottom: 34px;
        text-align: center;
        p{
          margin: 0;
          &:not(:first-of-type){
            margin-top: 22px;
          }
        }
        ul{
          li{
            font-weight: 300;
            &::before{
              background-color: white;
            }
          }
        } 
      }

    }

  }
  
  .paragraphs-item-pb--adv-set{
    background-color: #ececec;
    /*margin-top: -109px;*/
    padding-top: 123px;
    padding-bottom: 62px;
    margin-bottom: 119px;
    > .content{
      > .field-name-field-title-decor{
        line-height: 1;
        margin-bottom: 56px;
        .field-item{
          padding: 0 0 0 30px;
          &::before,
          &::after{
            content: none;
          }
        }
      }
      > .group-wrap{
        > .paragraphs-items-field-pbas-adv{
          justify-content: space-between;
          > .paragraphs-item-pb--adv-block{
            width: 323px;
            margin-bottom: 47px;
          }
        }
      }
    }
  }

  .paragraphs-items-field-pbas-adv{
    > .content{

    }
    .field-name-field-image{
      min-height: 196px;
      .field-item{
        display: inline-flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        min-width: 172px;
        min-height: 171px;
        background: url('../images/dealers/why-bg.png') center no-repeat!important;
        img{
          position: relative;
          left: 3px;
        }
      }
    }
    .field-name-field-title{
      color: #676447;
      font-family: $FuturaLight;
      font-size: 17px;
      font-weight: 400;
      text-transform: uppercase;
      min-height: 41px;
    }
    .field-name-field-description{

    }
  }
  
  .paragraphs-item-pb--adv-partnership{
    margin-bottom: 72px;
    > .content{
      > .field-name-field-title{
        font-size: 0;
        text-align: center;
        margin-bottom: 86px;
        .field-item{
          display: inline-block;
          color: #676447;
          font-family: $FuturaLight;
          font-size: 30px;
          font-weight: 400;
          line-height: 1;
          text-transform: uppercase;
          padding: 0 0 0 21px;
          border-left: 5px solid #fcc82b;
        }
      }
      > .group-wrap{
        > .paragraphs-items-field-pbap-adv{
          > .paragraphs-item-pb--adv-partnership-blk{

          }
        }
      }
    }
  }

  .paragraphs-item-pb--adv-partnership-blk{
    padding-bottom: 49px;
    margin-bottom: 49px;
    border-bottom: 5px solid #ececec;
    > .content{
      display: flex;
      flex-direction:row;
    }
    .field-name-field-image{
      margin-right: 53px;
      margin-top: 2px;
    }
    .group-right{
      margin-top:auto;
      margin-bottom: auto;
      width: calc(100% - 253px);
      .field-name-field-title{
        color: #fcc82b;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 19px;
      }
      .field-name-field-description{
        color: #202223;
        font-size: 16px;
        font-weight: 300;
        p{
          margin-bottom: 0px;
          line-height: 1.3;
          &:not(:last-child){
            margin-bottom: 37px;
          }
        }
        .with-icon{
          font-weight: 700;
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 1.24;
          &:not(:last-child){
            margin-bottom: 21px;
          }
          img{
            margin-right: 31px;
          }
          .img{
            min-width: 82px;
            img{
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  .official-dealer{
    .of__container{
      padding-left: 36px;
      padding-right: 36px;
    }
    .of__title{
      color: #676447;
      font-family: $FuturaLight;
      font-size: 30px;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      border-left: 5px solid #fcc82b;
      padding-left: 21px;
      margin-top: 5px;
      margin-bottom: 35px;
    }
    .field-name-field-content{
      ul{
        margin-bottom: 55px;
        li{
          margin-bottom: 10px;
          line-height: 1.46;
        }
      } 
      p{
        a{
          img{
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

  }
  
  .bg-grey{
    padding: 114px 0 106px 0;
  }
  
  .paragraphs-item-pb--our-awards{
    margin-bottom: 100px;
  }

}


#block-webform-client-block-313{
  background-image: url('../images/dealers/bg_webform.jpg');
  background-repeat: no-repeat;
  background-position: center top;
    background-size: cover;
    position: relative;
  padding-top: 145px;
  min-height: 737px;
  margin-bottom: 165px;
  &::before{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 45px;
    background: url(../images/dealers/trafaret__webform__top.png) center top no-repeat;
  }
  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 45px;
    background: url(../images/trafaret__solut-farm-front__bottom.png) center top no-repeat;
  }
  #webform-client-form-313{
    @include make-container();
  }
}

#webform-client-form-313{
  .webform-component-fieldset{
    margin-bottom: 0px;
    background-color: transparent;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
    > .panel-body{
      padding: 0;
    }
  }

  input[type="text"],
  input[type="email"]{
    width: 100%;
    height: 60px;
    background-color: white;
    border-radius: 0px;   
    color: #676447;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    box-shadow: none;
    border:0;
    padding: 0 0 0 30px;
    &:hover,
    &:focus{
      outline: 0;
      box-shadow: none;
    }
    @include placeholder(){
      color: #676447;
      font-size: 14px;
      font-weight: 300;
    }
  }

  textarea{
    width: 100%;
    height: 120px;
    background-color: white;
    border-radius: 0px;
    color: #676447;
    font-size: 14px;
    font-weight: 300;
    resize:none;
    text-align: left;
    box-shadow: none;
    border:0;
    padding-top: 50px;
    &:hover,
    &:focus{
      outline: 0;
      box-shadow: none; 
    }
    &:focus{
      padding-top: 10px;
    }
    @include placeholder(){
      color: #676447;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      text-align-last: center;
    }
  }

  .form-actions{
    text-align: center;
  }

  input[type="submit"]{
    width: 178px;
    height: 50px;
    background-color: #fcc82b;
    border-radius: 25px;
    box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
    color: white;
    font-family: $FuturaLight;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    border:0;
  }
  
  .form-item{
    margin-bottom: 50px;
  }

  .webform-component--wrap{
    > .panel-body{
      display: flex;
      flex-direction:row;
      justify-content:space-between;
      &::before,
      &::after{
        content: none;
      }
    }
  }

  .webform-component--wrap--imya,
  .webform-component--wrap--nomer-telefona,
  .webform-component--wrap--e-mail{
    width: 356px;
  }
  
  .webform-component--zagolovok{
    color: #fffffe;
    font-family: $FuturaLight;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 44px;
    p{
      margin: 0;
    }
  }

}

@include respond-to('medium'){
  
  body.node-type-m-terms-dealers{
    .node-m-terms-dealers{
      .big-image{
        z-index: 3;
        .field-name-field-mtd-big-img{
          .field-item{
            background-size: cover;
            img{
              height: 450px;
            }
          }
        } 
        .node-title{
          margin: 204px 0 0 0px;
        }
      } 
      .yellow-block{
        min-height: initial;
        padding-top: 100px;
        padding-bottom: 50px;
        background: url(../images/machine/bg__yellow-block-mob.jpg) center center repeat;
      }
    } 

    .paragraphs-item-pb--adv-set{
      margin-top: 0px;
      padding-top: 80px;
      margin-bottom: 80px;
      >.content{
        >.group-wrap{
          >.paragraphs-items-field-pbas-adv{
            justify-content: space-around;
          }
        }
      }
    }

    .paragraphs-item-pb--adv-partnership-blk{
      margin-bottom: 50px;
      >.content{

      }
      .field-name-field-image{
        margin-right: 25px;
      }
      .group-right{
        width: calc(100% - 225px);
      }
    }
    
    .bg-grey{
      padding: 80px 0 80px 0;
    }

    .official-dealer{
      .field-name-field-content{
        p{
          a{
            img{
              max-width: 100%;
              height: auto;
            }
          } 
        } 
      } 
    } 
  } 
  
  #block-webform-client-block-313{
    padding-top: 95px;
    margin-bottom: 95px;
  }

  #webform-client-form-313{
    .webform-component--wrap{
      >.panel-body{
        flex-wrap:wrap;
        > *{
          &:last-child{
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  } 

}

@include respond-to('small'){
  
  body.node-type-m-terms-dealers{
    .node-m-terms-dealers{
      .big-image{
        z-index: 3;
        .field-name-field-mtd-big-img{
          .field-item{
            background-size: cover;
            img{
              height: 450px;
            }
          }
        } 
        .node-title{
          margin: 175px 0 0 0px;
        }
      } 
      .yellow-block{
        min-height: initial;
        padding-top: 100px;
        padding-bottom: 50px;
        background: url(../images/machine/bg__yellow-block-mob.jpg) center center repeat;
      }
    } 

    .paragraphs-item-pb--adv-set{
      margin-top: 0px;
      padding-top: 80px;
      margin-bottom: 80px;
      >.content{
        >.group-wrap{
          >.paragraphs-items-field-pbas-adv{
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            > .paragraphs-item-pb--adv-block{
              max-width: 323px;
              width: 100%;
            }
          }
        }
      }
    }

    .paragraphs-item-pb--adv-partnership{
      >.content{
        >.field-name-field-title{
          padding: 0 10px;
          margin-bottom: 50px;
          .field-item{
            font-size: 22px;
            padding: 0 0 0 10px;
          }
        }
      }
    }

    .paragraphs-item-pb--adv-partnership-blk{
      margin-bottom: 50px;
      >.content{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .field-name-field-image{
        margin-right: 0px;
        margin-bottom: 25px;
      }
      .group-right{
        width: initial;
        .field-name-field-description{
          .with-icon{
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            .img{
              min-width: initial;
              margin-bottom: 20px;
            }
          }
        } 
      }
    }
    
    .bg-grey{
      padding: 80px 0 80px 0;
    }

    .official-dealer{
      .of__container{
        padding-left: 10px;
        padding-right: 10px;
      }
      .of__title{
        font-size: 20px;
        padding-left: 10px;
      }
      .field-name-field-content{
        p{
          a{
            img{
              max-width: 100%;
              height: auto;
            }
          } 
        } 
        ul{
          li{
            &::before{
              top: -7px;
            }
          }
        }
      } 
    } 

    .paragraphs-item-pb--our-awards{
      margin-bottom: 80px;
    }
  } 
  
  #block-webform-client-block-313{
    padding-top: 95px;
    margin-bottom: 60px;
    padding-bottom: 95px;
    min-height: initial;
  }

  #webform-client-form-313{
      
    .form-item{
      margin-bottom: 30px;
    }

    .webform-component--zagolovok{
      font-size: 16px;
    }

    .webform-component--wrap{
      >.panel-body{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .webform-component--wrap--imya, 
    .webform-component--wrap--nomer-telefona, 
    .webform-component--wrap--e-mail{
      max-width: 356px;
      width: 100%;
    }
  } 
  
}