#about-factory-wrap1 {
  background: url('../images/bg__front-set-1.png') center bottom 320px no-repeat;
}
body.node-type-about-factory {
  .page-header {
    h1.title {
      display: none;
    }
  }
  .slider-section {
    position: relative;
    z-index: 1;
    .node-title-wrap {
      position: absolute;
     // bottom: 460px;
      left: 0;
      width: 100%;
      z-index: 2; 
      top: calc((100% - 380px)/2);
      @include respond-to('medium') {
        bottom: 200px;
      }
      @include respond-to('small') {
        top: 0;
      }
      .field-name-field-formatted-title {
        border-left: 5px solid #fcc82b;
        padding: 0 0 0 19px;
        margin: 0 0 0 9px;
        color: #676447;
        font-family: $FuturaLight;
        font-size: 40px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        @include respond-to('medium') {
          font-size: 28px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .yellow-engraving {
    min-height: 438px;
    background: url('../images/our_innova/bg__yellow-square-engraving.png') center top no-repeat;
    margin-top: -74px;
    z-index: 4;
    position: relative;
    margin-bottom: 43px;
    @include respond-to('small') {
      background: none;
      margin-bottom: 117px;
      margin-top: 0;
    }
    .field-name-body {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      text-align: center;
      padding: 91px 28px 0 28px;
      @include respond-to('medium') {
        padding-top: 50px;
      }
      @include respond-to('small') {
        padding: 60px 10px;
      }
    }
  }
  .paragraphs-item-pb--video {
    margin-top: -117px;
    .field-name-field-pbv-logo {
      display: none;
    }
    .field-name-field-title-decor-big {
      margin-top: 120px;
    }
    .field-name-field-description {
      font-size: 14px;
      padding-left: 30px;
      padding-right: 30px;
      text-transform: none;
    }
  }
  .paragraphs-item-pb--adv-set {
    &>.content {
      .paragraphs-items-field-pbas-adv {
        justify-content: space-between;
        @include respond-to('medium') {
          justify-content: center;
        }
      }
    }
  }
  .paragraphs-item-pb--text-area {
    margin-bottom: 114px;
  }
}
.paragraphs-item-pb--video {
  .field-name-field-title-decor-big {
    color: #fff;
    font-size: 30px;
  }
}
.paragraphs-item-pb--principles {
  margin-bottom: 114px;
  .field-name-field-title-decor {
    @include make-container();
  }
}
.paragraphs-items-field-principles {
  .paragraphs-item-pb--principle {
    &:nth-child(2n) {
      background-color: #f2f2f2;
    }
    > .content {
      @include make-container();
      align-items: stretch;
      display: flex;
      padding: 40px 0;
      @include respond-to('small') {
        flex-wrap: wrap;
      }
    }
    .field-name-field-principle-title {
      align-items: center;
      border-left: 5px solid #fcc82b;
      color: #676447;
      display: flex;
      font-family: $FuturaLight;
      font-size: 17px;
      line-height: 20px;
      padding-right: 40px;
      text-align: right;
      text-transform: uppercase;
      justify-content: flex-end;
      width: 268px;
      @include respond-to('small') {
        border: none;
        padding-left: 10px;
        padding-riht: 10px;
        text-align: left;
        justify-content: flex-start;
        width: 100%;
        br {
          display: none;
        }
      }
      .yellow {
        color: #fcc82b;
        font-family: $FuturaBlack;
      }
      p {
        margin: 0;
      }
    }
    .field-name-field-principle-desc {
      border-left: 5px solid #fcc82b;
      color: #202223;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      padding: 10px 0 0 40px;
      width: calc(100% - 268px);
      @include respond-to('small') {
        border: none;
        padding-left: 10px;
        padding-riht: 10px;
        width: 100%;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }
}
.paragraphs-item-pb--our-partners {
  margin-bottom: 114px;
  > .content {
    @include make-container();
  }
  .field-name-field-title-decor {
    margin-bottom: 40px;
  }
}
.paragraphs-items-field-partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include respond-to('medium') {
    justify-content: center;
  }
  .paragraphs-item-pb--partner {
    width: 18%;
    @include respond-to('medium') {
      margin: 10px 2.5%;
      width: 30%;
    }
    @include respond-to('small') {
      width: 100%;
    }
    .field-name-field-partner-logo {
      align-items: center;
      display: flex;
      height: 100px;
      justify-content: center;
      width: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .field-name-field-partner-name {
      color: #202223;
      font-size: 14px;
      padding: 20px 0 0 0;
      text-align: center;
    }
  }
}








