body.node-type-m-dir-sparepart{
	
	#content{
		background-color: #ececec;
	}

	.node-m-dir-sparepart{

		.big-image{
			position: relative;
			.field-name-field-mdsp-background{
				.field-item{
					background-position: center top;
					@include respond-to('medium'){
		            	height: 450px;
		            	background-size: cover;
			        }
			        @include respond-to('small'){
			        	height: 450px;
			            background-size: cover;
			        }
				}
			}
			> .bi__container{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
			}
			.node-title-wrap{
				margin: 208px 0 0 0px;
				text-align: center;
				font-size: 0;	
			}
			.field-name-field-formatted-title{
				display: inline-block;
				padding: 0 0 0 19px;
				border-left: 5px solid #fcc82b;
				color: white;
				font-family: $FuturaLight;
				font-size: 40px;
				font-weight: 400;
				line-height: 1;
				text-transform: uppercase;
				p{
					margin: 0;
				}
			}
			.field-name-body{
				color: white;
				font-size: 16px;
				font-weight: 300;
				line-height: 26px;
				text-align: center;
				margin-top: 34px;
			}
		}

	}

	.pane-node-field-mdsp-outro-text{
		margin-bottom: 197px;
	}
	
	.field-name-field-mdsp-outro-text{
		text-align: center;
		font-size: 14px;
		font-weight: 300;
		line-height: 22px;
		p{
			margin-bottom: 0px;
			&:not(:last-child){
				margin-bottom: 22px;
			}
		}
	}

	.pane-entity-view{
		margin-bottom: 110px;
	}

}

.pane-v-dir-sparepart{
	margin-bottom: 73px;
	.pane-title{
		color: #676447;
		font-family: $FuturaLight;
		font-size: 30px;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		margin: 0 0 0 0;
		padding: 15px 0 15px 20px;
		border-left: 5px solid #fcc82b;
		p{
			margin: 0;
		}
	}
	.pane-content{
		margin-top: -61px;
	}
}

.view-v-dir-sparepart{
	.view-filters{
		margin-bottom: 60px;

		.views-exposed-widgets{
			display: flex;
			flex-direction:row;
			justify-content:flex-end;
			> *{
				&:not(:first-child){
					margin-left: 51px;
				}
			}
			> .views-submit-button{
				margin: 0!important;
			}
		}

		.form-item-sort-bef-combine,
		.form-item-items-per-page{
			> label{
				display: none;
			}
		}

		.form-control.jq-selectbox{
			margin-left: auto;
		}
	}


	.view-content{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:space-between;
		.views-row{
			width: 356px;
			margin-bottom: 50px;
			&:hover{
				.node-m-sparepart.node-teaser{
					.field-name-field-msp-img-preview{
						.field-item{
							img{
								box-shadow: none;
							}
						}
					}
					.field-name-field-formatted-title{
						border-color: #4cabca;
					}
					.node-detail{
						a{
							background-color: #4cabca;
						}
					}
				}
			}
			.node-m-sparepart.node-teaser{
				.field-name-field-msp-img-preview{
					.field-item{
						background: none!important;
						img{
							box-shadow: 0 12px 12px rgba(0, 0, 0, 0.07);
						}
					}
				}
				.bottom{
					height: 333px;
					background-color: white;
					display: flex;
					flex-direction:column;
					justify-content:flex-end;
					padding: 0px 0 0 49px;
					overflow: hidden;
				}
				.field-name-field-formatted-title{
					font-family: $FuturaLight;
					font-size: 15px;
					font-weight: 400;
					text-transform: uppercase;
					margin: 0 0 30px 0;
					padding: 0 0 0 10px;
					border-left: 5px solid #fcc82b;
					line-height: 1.2;
					min-height: 47px;
					display: flex;
					flex-direction: row;
					align-items: center;
					overflow: hidden;
					p{
						margin: 0;
					}
				}
				.field-name-field-msp-text-preview{
					font-size: 14px;
					font-weight: 300;
					line-height: 22px;
					margin-bottom: 14px;
					p{
						margin: 0;
					}
				}
				.node-detail{
					text-align: right;
					font-size: 0;
					a{
						display: inline-block;
						width: 60px;
						height: 60px;
						background-color: #fcc82b;
						background-image: url('../images/arr_right.png');
						background-repeat: no-repeat;
						background-position: center center;
					}
				}
			}
		}
	}

	.item-list{
		ul.pager{
			margin: 40px 0 40px 0;
		}
	} 

}

@include respond-to('medium'){
	body.node-type-m-dir-sparepart{
		.node-m-dir-sparepart{
			.big-image{
				.field-name-body{
					br{
						display: none;
					}
				}
			} 
		} 
		.pane-entity-view{
			margin-bottom: 80px;
		}
		.pane-node-field-mdsp-outro-text{
			margin-bottom: 147px;
		}
	} 
	.pane-v-dir-sparepart{
		margin-bottom: 50px;
		.pane-content{
			margin-top: 40px;
		}
	} 
	.view-v-dir-sparepart{
		.view-filters{
			.views-exposed-widgets{
				justify-content:center;
				>*:not(:first-child){
					margin-left: 36px;
				}
			}
		} 
	} 
}

@include respond-to('small'){
	body.node-type-m-dir-sparepart{
		.node-m-dir-sparepart{
			.big-image{
				.node-title-wrap{
					margin: 88px 0 0 0px;
					.field-name-field-formatted-title{
						font-size: 30px;
						padding: 0 0 0 10px;
					}
				}
				.field-name-body{
					br{
						display: none;
					}
				}
			} 
		} 
		.pane-entity-view{
			margin-bottom: 70px;
		}
		.pane-node-field-mdsp-outro-text{
			margin-bottom: 127px;
		}
	} 

	.pane-v-dir-sparepart{
		margin-bottom: 40px;
		.pane-content{
			margin-top: 40px;
		}
	} 

	.view-v-dir-sparepart{
		.view-filters{
			.views-exposed-widgets{
				flex-direction:column;
				justify-content:flex-start;
				align-items:center;
				>*:not(:first-child){
					margin-left: 0px;
					margin-top: 20px;
				}
				>*:not(:last-child){
					width: 100%;
				}
			}
			.form-control.jq-selectbox{
				width: 100%;
				max-width: 356px;
			}
		} 
		.view-content{
			justify-content:center;
			.views-row{
				.node-m-sparepart.node-teaser{
					.field-name-field-msp-img-preview{
						img{
							max-width: 100%;
							height: auto;
						}
					}
					.bottom{
						min-height: 333px;
						height: initial;
						padding: 0px 0 0 19px;
					}
				}
			}
		}
	} 
}