#cboxWrapper{
  background: transparent!important;
  overflow: visible!important;
}
#colorbox,
#cboxOverlay,
#cboxCurrent,
#cboxContent{
  overflow: visible!important;
}

#cboxPrevious {
  float: none!important;
  position: absolute!important;
  top: 0px!important;
  bottom: 0px!important;
  left: -68px!important;
  margin: auto 0;
  width: 45px!important;
  height: 46px!important;
  background: url(../images/colorbox__left.png) no-repeat!important;
  &:focus{
  	outline: none;
  }
  &:hover{
    background: url(../images/colorbox__left-hover.png) no-repeat!important;
  }
}

/*#colorbox:hover #cboxPrevious{
	display: inline-block!important;
}*/

#cboxNext {
  float: none!important;
  position: absolute!important;
  top: 0px!important;
  bottom: 0px!important;
  left: auto!important;
  right: -68px!important;
  margin: auto 0;
  width: 45px!important;
  height: 46px!important;
  background: url(../images/colorbox__right.png) no-repeat!important;
  &:focus{
  	outline: none;
  }
  &:hover{
    background: url(../images/colorbox__right-hover.png) no-repeat!important;
  }
}

/*#colorbox:hover #cboxNext{
	display: inline-block!important;
}*/

#cboxClose{
	right: 2px!important;
	top: -30px!important;
	bottom: auto!important;
	width: 16px!important;
	height: 15px!important;
  	background: url(../images/colorbox__close.png) no-repeat!important;
  	&:focus{
  		outline: none;
  	}
}

#cboxCurrent{
	display: none!important;
}
#cboxOverlay{
	background: #363636;
	opacity: 0.8 !important;
}

#cboxContent{
	background-color: transparent!important;
}

#cboxLoadedContent{
	margin-bottom: 0px!important;
}

#cboxTitle{
  color: white;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  display: block!important;
  float: none!important;
  background: none!important;
  padding: 0;
  height: initial!important;
  bottom: auto;
  top: calc(100% + 36px);
}

/*#colorbox,
#cboxWrapper,
#cboxContent,
#cboxLoadedContent{
  min-width: 1200px!important;
}*/

/*#colorbox,
#cboxWrapper{
  width: 1200px!important;
}

#cboxContent,
#cboxLoadedContent{
  width: 1170px!important;
}*/