.lang-switch-group{
	position: relative;
	display: flex;
	flex-direction:row;
	align-items:center;
	justify-content: flex-end;
	cursor: pointer;
	.lang__cur{
		color: #202223;
		font-family: $FuturaLight;
		font-size: 14px;
		font-weight: 400;
	}
	.lang__icon{
		width: 33px;
		height: 17px;
		background: url('../images/icon__flat-world.png') no-repeat;
		margin: 4px 0px 0 10px;
	}
	.lang__label{
		color: #202223;
		font-family: $FuturaLight;
		font-size: 14px;
		font-weight: 400;
		margin-left: 10px;
	}
	.lang__list{
		position: absolute;
		left: 50%;
		top: 100%;
		background-color: #ffffff;
		z-index: 10;
		margin-left: -55px;
		.lang__wrap{
			width: 145px;
			.lang{
				a{
					display: block;
					padding: 7px 5px 7px 5px;
					color: rgb(180, 180, 180);
					font-family: Helvetica, Arial, sans-serif;
					font-size: 12px;
					line-height: 1;
					text-decoration: none;
				}
			}
		}
	}
}

#header{
	position: relative;
	z-index: 200;
	.header-top{
		background-color: white;
		> .ht__container{
			height: 130px;
			@include respond-to('tablet_mobile'){
				position: relative;
				display: flex;
				flex-direction:column-reverse;
				align-items:center;
				justify-content: flex-end;
			}
		}

		#logotype{
			@include respond-to('large'){
				margin: 11px 0 0 0;
				float: left;
			}
			@include respond-to('tablet_mobile'){
				margin-top: 10px;
				img{
					width: 76px;
					height: 75px;
				}
			}
		}

		.region-header-top{
			display: flex;
			flex-direction:row;
			@include respond-to('large'){
				float: left;
			    padding: 25px 0 0 30px;
			    width: calc(100% - 190px);
			}
			@include respond-to('tablet_mobile'){
				width: 100%;
				margin-top: 11px;
			}
		}
		
		.region-blockgroup-ht-wrap-1{
			@include respond-to('large'){
				padding: 3px 0 0 0;
			}
			@include respond-to('tablet_mobile'){
				width: 100%;
			}
		}

		.telephones{
			display: flex;
			flex-direction:row;
			.tel{
				font-family: $FuturaLight;
				font-size: 24px;
				font-weight: 400;
				color: #5d5747;
				@include respond-to('tablet_mobile'){
					font-size: 15px;
				}
				&:not(:last-child){
					@include respond-to('large'){
						margin-right: 52px;
					}
				}
				.tel-icon{
					display: inline-block;
					width: 24px;
					height: 24px;
					background: url('../images/icon__tel.png') no-repeat;
					margin: 0px 5px 0 0;
					position: relative;
					top: 3px;
					@include respond-to('tablet_mobile'){
						width: 15px;
						height: 16px;
						background-size: 100%;
						margin: 0px 2px 0 0;
					}
				}
				a{
					color: #5d5747;
				}
			}
			@include respond-to('tablet_mobile'){
				.tel-1{
					margin-right: auto;
				}
				.tel-2{
					margin-left: auto;
				}
			}
		}

		#block-system-main-menu{
			margin-top: 21px;
			@include respond-to('tablet_mobile'){
				display: none;
			}
			.block__content{
				> ul{
					padding: 0 0 0 0;
					margin: 0 0 0 0;
					font-size: 0;
					> li{
						list-style: none;
						display: inline-block;
						position: relative;
						&:not(:last-child){
							margin-right: 19px;
						}
						> a, > span{
							display: block;
							padding: 0 0 0 0;
							color: #202223;
							font-family: $FuturaLight;
							font-size: 14px;
							font-weight: 400;
							text-transform: uppercase;
							background-color: transparent;
							position: relative;
							&:hover{
								background-color: transparent;
								color: #4cabca;
							}
							span, .caret{
								display: none;
							}
						}
						&.expanded{
							margin-right: 13px;
							> a, > span{
								padding: 0 10px 0 0;
								&::after{
									content: '';
									position: absolute;
									top: 0;
									bottom: 0;
									right: 0;
									margin: auto 0;
									width: 7px;
									height: 4px;
									background: url('../images/caret-dropdown-black.png') no-repeat;
								}
								&:hover{
									&::after{
										background: url('../images/caret-dropdown-blue.png') no-repeat;
									}
								}
							}	
						}
						&:hover{
							> a, > span{
								color: #4cabca;
								&::after{
									background: url('../images/caret-dropdown-blue.png') no-repeat;
								}
							}
							> ul{
								display: block;
							}
						}
						> ul {
							display: none;
							padding: 12px 30px 16px 30px;
							margin: 0 0 0 0;
							font-size: 0;
							position: absolute;
							top: 100%;
							left: -30px;
							background-color: white;
							box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
							z-index: 10;
							> li{
								list-style: none;
								&:not(:last-child){
									margin-bottom: 13px;
								}
								> a{
									display: block;
									padding: 0;
									color: #202223;
									font-family: $FuturaLight;
									font-size: 12px;
									font-weight: 400;
									text-transform: uppercase;
									background-color: transparent;
									white-space: nowrap;
									&:hover{
										background-color: transparent;
										color: #4cabca;
									}
								}
							}
						}
					}
				}
			}
		}

		#block-menu-menu-social-menu{
			margin: 5px 0 0 57px;
			@include respond-to('tablet_mobile'){
				display: none;
			}
			.block__content{
				> ul{
					padding: 0 0 0 0;
					margin: 0 0 0 0;
					display: flex;
					flex-direction:row;
					flex-wrap:wrap;
					justify-content: center;
					width: 104px;
					&::before,
					&::after{
						content: none;
					}
					> li {
						background: none!important;
						margin-bottom: 11px;
						&:not(:last-child):not(:nth-child(3n+3)){
							margin-right: 10px;
						}
						> a{
							font-size: 0;
							text-indent: -9999px;
							padding: 0 0 0 0;
							background-color: transparent;
							display: flex;
							flex-direction:row;
							justify-content:center;
							align-items:center;
							width: 28px;
							height: 28px;
							background-color: #d2d3d4;
							border-radius: 14px;
							> img{
								
							}
							.icon-item{
								display: flex;
							}
							&:hover{
								background-color: #d2d3d4;
							}
						}
					}
				}
			}
		}
		#block-block-5{
			margin: 5px 0 0 30px;
			@include respond-to('tablet_mobile') {
				display: none;
			}
			.block__content{
				> ul{
					padding: 0 0 0 0;
					margin: 0 0 0 0;
					display: flex;
					flex-direction:row;
					flex-wrap:wrap;
					justify-content: center;
					width: 104px;
					&::before,
					&::after{
						content: none;
					}
					> li {
						list-style: none;
						background: none!important;
						margin-bottom: 11px;
						&:not(:last-child):not(:nth-child(3n+3)){
							margin-right: 10px;
						}
						> a{
							font-size: 0;
							text-indent: -9999px;
							padding: 0 0 0 0;
							background-color: transparent;
							display: flex;
							flex-direction:row;
							justify-content:center;
							align-items:center;
							width: 28px;
							height: 28px;
							background-color: #d2d3d4;
							border-radius: 14px;
							> img{
								
							}
							.icon-item{
								display: flex;
							}
							&:hover{
								background-color: #d2d3d4;
							}
							&.fb {
								background: url('../images/icon/fb.png');
							}
							&.yt {
								background: url('../images/icon/wa.png');
							}
							&.in {
								background: url('../images/icon/insta.png');
							}
							&.skype {
								background: url('../images/icon/skype.png');
							}
							&.vb {
								background: url('../images/icon/vibe.png');
							}
						}
					}
				}
			}
		}
		
		.region-blockgroup-ht-wrap-2{
			margin-left: auto;
			@include respond-to('tablet_mobile') {
				display: none;
			}
		}
		
		#block-block-2{
			margin-top: 16px;
		}

		.calc-effict{
			a{
				display: flex;
				flex-direction:row;
				justify-content:center;
				align-items:center;
				width: 241px;
				height: 39px;
				border: 1px solid #fdb92c;
				border-radius: 20px;
				color: #202223;
				font-family: $FuturaLight;
				font-size: 13px;
				font-weight: 400;
				text-transform: uppercase;
				color: #202223;
			}
		}
		
		#burger{
			position: absolute;
			top: 63px;
			right: 10px;
			width: 34px;
			height: 33px;
			background-color: #fcc82b;
			border-radius: 100%;
			cursor: pointer;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url('../images/burger.png');
			@include respond-to('large'){
				display: none;
			}
		}

	}
	
	.header-menu{
		background-color: rgba(252, 200, 43, 0.9);
		box-shadow: 5.4px 5.9px 8px rgba(0, 0, 0, 0.12);
		@include respond-to('tablet_mobile'){
			display: none;
		}
		.hm__container{
			height: 30px;
		}

		#block-menu-menu-support-menu{
			.block__content{
				> ul{
					padding: 0 10px 0 10px;
					margin: 0 0 0 0;
					display: flex;
					flex-direction:row;
					justify-content:space-between;
					&::before,
					&::after{
						content: none;
					}
					> li{
						list-style: none;
						> a , > span{
							display: flex;
							flex-direction:row;
							align-items:center;
							justify-content:center;
							height: 30px;
							padding: 0 20px 0 20px;
							color: white;
							font-family: $FuturaLight;
							font-size: 14px;
							font-weight: 400;
							text-transform: uppercase;
							letter-spacing: 0.16px;
							word-spacing: 2px;
							&:hover{
								background-color: white;
								color: #fcc82b;
							}
							span{
								display: none;
							}
						}
						&:hover{
							> a , > span{
								background-color: white;
								color: #fcc82b;
							}
							> ul{
								display: block;
							}
						}
						&.expanded{
							> a, > span{
								padding: 0 35px 0 20px;
								&::after{
									content: '';
									position: absolute;
									top: 0;
									bottom: 0;
									right: 20px;
									margin: auto 0;
									width: 7px;
									height: 6px;
									background: url('../images/caret-dropdown-white.png') no-repeat;
								}
								&:hover{
									&::after{
										background: url('../images/caret-dropdown-yellow.png') no-repeat;
									}
								}
							}
							&:hover{
								> a, > span{
									&::after{
										background: url('../images/caret-dropdown-yellow.png') no-repeat;
									}
								}
							}	
						}
						> ul {
							display: none;
							padding: 25px 24px 25px 25px;
							margin: 0 0 0 0;
							font-size: 0;
							position: absolute;
							top: 100%;
							left: 0px;
							background-color: white;
							box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
							min-width: 100%;
							> li{
								list-style: none;
								display: inline-block;
								> a{
									display: block;
									padding: 0;
									color: #202223;
									font-family: $FuturaLight;
									font-size: 13px;
									font-weight: 400;
									text-transform: uppercase;
									background-color: transparent;
									&:hover{
										background-color: transparent;
									}
								}
							}
						}
						&:not(.catalog):not(.sparepart){
							> ul{
								left: -10px;
								> li{
									width: 100%;
									> a{
										white-space: nowrap;
										border-bottom: 1px solid #d0d3d4;
										padding: 11px 6px 10px 4px;
										&:hover{
											color: #4cabca;
										}
									}
								}
							}
						}
						&.catalog{
							&:hover{
								> ul{
									display: flex;
								}
							}
							> ul{
								width: 708px;
								padding: 40px 30px 0 30px;
								flex-direction:row;
								flex-wrap:wrap;
								justify-content:space-between;
								&::before,
								&::after{
									content: none;
								}
								> li{
									&:last-child{
										a{
											border-bottom: none;
										}
									}
									> a{
										display: flex;
										flex-direction: row;
										align-items: center;
										font-size: 13px;
										line-height: 1.2;
										border-bottom: 1px solid #d0d3d4;
										padding-bottom: 20px;
										margin-bottom: 20px;
										color: #676447;
										.icon-item{
											margin-right: 11px;
											.img-hover{
												display: none;
											}
										}
										&:hover{
											color: #fcc82b;
											.icon-item{
												.img-main{
													display: none;
												}
												.img-hover{
													display: block;
												}
											}
										}
									}
								}
							}
						}

						&.sparepart{
							&:hover{
								> ul{
									display: flex;
								}
							}
							> ul{
								width: 305px;
								padding: 37px 30px 40px 30px;
								left: -20px;
								flex-direction:column;
								align-items:center;
								&::before,
								&::after{
									content: none;
								}
								> li{
									width: 100%;
									&:last-child{
										a{
											//border-bottom: none;
										}
									}
									> a{
										display: flex;
										flex-direction: row;
										align-items: center;
										font-size: 13px;
										line-height: 1.2;
										border-bottom: 1px solid #d0d3d4;
										padding-bottom: 20px;
										margin-bottom: 20px;
										color: #676447;
										.icon-item{
											margin-right: 20px;
											margin-left: 10px;
											.img-hover{
												display: none;
											}
										}
										&:hover{
											color: #fcc82b;
											/*.icon-item{
												.img-main{
													display: none;
												}
												.img-hover{
													display: block;
												}
											}*/
										}
									}
									&.button{
										> a{
											justify-content:center;
											padding-bottom: 0px;
											margin: 10px auto 0 auto;
											width: 225px;
											height: 39px;
											border: 1px solid #fdb92c;
											border-radius: 20px;
											color: #202223;
											font-size: 12px;
											font-weight: 400;
											text-transform: uppercase;
											&:hover{
												background-color: #4cabca;
												color: white;
												border-color: #4cabca;
											}
										}
										&:hover{
											> a{
												background-color: #4cabca;
												color: white;
												border-color: #4cabca;
											}
										}
									}
								}
							}

						}

					}
				}
			}
		}

	}


}