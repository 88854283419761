body.node-type-m-machine {
  .page-header {
    h1.title {
      display: none;
    }
  }
  .front-bg {
  	background-size: cover;
  }
  .node-m-machine{
    .big-image{
      position: relative;
      background: #eeeeee;
      .field-name-field-mm-mobile-img{
        display: none;
        .field-item{
          background-position: center top;
        }
      }
      @include respond-to('small') {
        .field-name-field-mm-mobile-img{
          display: block;
        }
        .field-name-field-mm-mobile-img + .field-name-field-mm-big-img{
          display: none;
        }
      }
      .field-name-field-mm-big-img{
        .field-item{
          background-position: center top;
        }
      }
      > .bi__container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
      .node-title-wrap{
        margin: 102px 0 0 9px;
        padding: 0 0 0 19px;
        border-left: 5px solid #fcc82b;
      }
      .field-name-field-formatted-title{
        color: #676447;
        font-family: $FuturaLight;
        font-size: 30px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
      }
      .field-name-field-mm-after-title{
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        margin: 29px 0 0 35px;
      }
      .field-name-field-mm-gallery{
        margin: 38px 0 0 34px;
        > .field-items{
          display: flex;
          flex-direction:row;
          flex-wrap:wrap;
          justify-content:space-between;
          width: 244px;
          > .field-item{
            margin-bottom: 10px;
            a{
              display: block;
              position: relative;
              &:hover{
                &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(252, 200, 43, 0.9);
                  background-image: url('../images/search.png');
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              }
              img{
                box-shadow: 0 12px 12px rgba(0, 0, 0, 0.07);
              }
            }
          }
        }
      }
    }

    .yellow-block{
      min-height: 473px;
      /*background: url('../images/machine/bg__yellow-block.png') center top / cover  no-repeat;*/
      background: url('../images/bg__yellow-block-new.png') center top  no-repeat;
      margin-top: -70px;
      z-index: 2;
      position: relative;
      padding-top: 74px;
      padding-bottom: 74px;
      display: flex;
      align-items: center;
      &:after {
        background: url('../images/after-yellow.png') center top  no-repeat;
        width: 100%;
        height: 74px;
        display: block;
        bottom: -74px;
        left: 0px;
        position: absolute;
        content: '';
      }
      .field-name-body{
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        margin-bottom: 34px;
        p{
          margin: 0;
          &:not(:first-of-type){
            margin-top: 22px;
          }
        }
        ul{
          li{
            font-weight: 300;
            &::before{
              background-color: white;
            }
          }
        } 
      }

      .links{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        > *{
          &:not(:last-child){
            margin-right: 30px;
          }
        }
      }

      .field-name-field-mm-pdf{
        .file{
          .file-icon,
          .file-size{
            display: none;
          }
          a{
            display: flex;
            flex-direction:row;
            justify-content:center;
            align-items:center;
            width: 256px;
            height: 50px;
            background-color: white;
            border-radius: 25px;
            box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
            color: #676447;
            font-family: $FuturaLight;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
          }
        }
      }

      .field-name-field-mm-3d-model{
        a{
          display: flex;
          flex-direction:row;
          justify-content:center;
          align-items:center;
          width: 256px;
          height: 50px;
          background-color: white;
          border-radius: 25px;
          box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
          color: #676447;
          font-family: $FuturaLight;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      #block-multiblock-1{
        a.ctools-use-modal{
          display: flex;
          flex-direction:row;
          justify-content:center;
          align-items:center;
          width: 256px;
          height: 50px;
          background-color: #4cabca;
          border-radius: 25px;
          box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
          color: white;
          font-family: $FuturaLight;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

    }
    
    .paragraphs-items-field-mm-paragraphs{
      //margin-top: -74px;
    }

  }
  
  .bg-grey{
    margin-bottom: 0px;
    padding: 114px 0 118px 0;
  }

  .paragraphs-item-pb--our-awards{
    margin-bottom: 68px;
    padding-top: 68px;
  }
  .paragraphs-item-pb--video {
    margin-bottom: 0px;
  }

  .paragraphs-item-pb--tabs-set {
    margin-top: 68px;
  }
  #cboxNext,#cboxPrevious {
    display: none!important;
  }

}


.paragraphs-item-pb--video{
  position: relative;
  z-index: 3;
  margin-bottom: 118px;
  .field-name-field-image{
    .field-item{
      background-position: center top;
    }
  }
  .v-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }
  .field-name-field-pbv-logo{
    margin-top: 140px;
    @include respond-to('medium') {
      margin-top: 90px;
    }
    .field-item{
      background-position: center top;
    }
  }
  .field-name-field-description{
    margin-top: 39px;
    color: white;
    font-family: $FuturaLight;
    font-size: 30px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
  }
  .video-link{
    text-align: center;
    font-size: 0;
    margin-top: 36px;
    a{
      display: inline-block;
    }
  }
}

.paragraphs-item-pb--tabs-set{
  margin-bottom: 116px;
  .ui-tabs{
    border:0;
    border-radius: 0;
    background: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    .form-control.jq-selectbox + .ui-tabs-nav{
      display: none;
    }
    .form-control.jq-selectbox{
      border: 1px solid #e0e0e0;
      box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
      max-width: 356px;
      width: 100%;
      margin: 0 auto 41px auto;
    }
    .ui-tabs-nav{
      border:0;
      border-radius: 0;
      background: none;
      margin: 0 0 46px 0;
      padding: 0 0 0 0;
      display: flex;
      flex-direction:row;
      flex-wrap:wrap;
      .ui-tabs-tab{
        border:0;
        border-left: 5px solid #fcc82b;
        border-radius: 0;
        background: none;
        margin: 0 0 30px 0;
        padding: 0 50px 0 50px;
        float: none;
        &:last-child{
          border-right: 5px solid #fcc82b;
        }
        .ui-tabs-anchor{
          float: none;
          padding: 0 0 0 0;
          color: #676447;
          font-family: $FuturaLight;
          font-size: 30px;
          font-weight: 400;
          line-height: 1;
          text-transform: uppercase;
          &:hover{
            color: #fcc82b;
          }
        }
        &.ui-tabs-active{
          .ui-tabs-anchor{
            color: #fcc82b;
          }
        }
      }
    }

    .ui-tabs-panel{
      padding: 0;
      border:0;
      border-radius: 0;
    }

  }

  .field-name-field-description{
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    ul{
      li{
        font-weight: 300;
      }
    } 
    p{
      margin: 0;
      &:not(:first-of-type){
        margin-top: 22px;
      }
    }
  }
}

.paragraphs-item-pb--packages-set{
  margin-bottom: 167px;
  .group-wrap{
    > .field-name-field-title{
      color: #676447;
      font-family: $FuturaLight;
      font-size: 30px;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      margin: 4px 0 42px 9px;
      padding: 0 0 0 21px;
      border-left: 5px solid #fcc82b;
    }
    > .paragraphs-items-field-pbps-packages{
      .paragraphs-item-pb--packages{

      }
    }
  }
}

.paragraphs-item-pb--packages{
  &:not(:last-child){
    margin-bottom: 80px;
  }
  > .content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .slick{
      width: 356px;
      margin-right: 64px;
      position: relative;
      .slick__slider{
        .slick-list{
          font-size: 0;
          .slick-slide{
            img{
              box-shadow: 0 12px 12px rgba(0, 0, 0, 0.07);
            }
          }
        }
      }
      .slick__arrow{
        .slick-arrow{
          position: absolute;
          bottom: 0;
          width: 60px;
          height: 60px;
          background-color: #fcc82b;
          background-repeat: no-repeat;
          background-position: center center;
          border:0;
        }
        .slick-prev{
          right: 62px;
          background-image: url('../images/arr_left.png');
        }
        .slick-next{
          right: 0;
          background-image: url('../images/arr_right.png');
        }
      }
    }
    .group-right{
      width: calc(100% - 420px);
      padding-top: 18px;
    }
    .field-name-field-title{
      font-size: 14px;
      line-height: 22px;
      color: #202223;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 22px;
    }
    .field-name-field-description{
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      ul{
        li{
          font-weight: 300;
        }
      }
      p{
        margin: 0;
        &:not(:first-of-type){
          margin-top: 22px;
        }
      }
    }
  }
}

.paragraphs-item-pb--reviews-machine{
  .group-wrap{
    > .field-name-field-title {
      font-size: 0px;
      border-left: 5px solid #fcc82b;
      .field-items{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          left: 0px;
          top: 0px;
          bottom: 0px;
          margin: auto 0;
          width: 100%;
          height: 5px;
          background-color: #fcc82b;
        }
      }
      .field-item{
        display: inline-block;
        padding: 0 37px 0 20px;
        color: #676447;
        font-family: $FuturaLight;
        font-size: 30px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        background-color: #ececec;
        z-index: 10;
        position: relative;
      }
    }
    > .field-name-field-pbrm-reviews{
      margin-top: -45px;
      .view-v-reviews{

        #block-multiblock-4{
          padding-left: 35px;
          background: #ececec;
          position: relative;
            z-index: 1;
          a.ctools-use-modal{
            background-color: #fcc82b;
            color: #5d5747;
          }
        } 

        .view-empty,
        .view-header{
          text-align: right;
          margin-bottom: 89px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
        .view-content{
          .views-row{
            &:last-child{
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

.pane-webform-client-block-158{
  background-color: #ececec;
  padding-top: 113px;
  padding-bottom: 183px;
  #webform-client-form-158{
    @include make-container();
  }
}

.pane-webform-client-block-158 {
  .alert-danger {
    display: none;
  }
  .error {
    border: 1px solid red!important; 
  }
}

#webform-client-form-158{
  .webform-component-fieldset{
    margin-bottom: 0px;
    background-color: transparent;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
    > .panel-body{
      padding: 0;
    }
  }

  input[type="text"],
  input[type="email"]{
    width: 100%;
    height: 50px;
    background-color: white;
    border-radius: 25px;    
    color: #676447;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    box-shadow: none;
    border:0;
    &:hover,
    &:focus{
      outline: 0;
      box-shadow: none;
    }
    @include placeholder(){
      color: #676447;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      text-align-last: center;
    }
  }

  textarea{
    width: 100%;
    height: 100px;
    background-color: white;
    border-radius: 25px;
    color: #676447;
    font-size: 14px;
    font-weight: 300;
    resize:none;
    text-align: left;
    box-shadow: none;
    border:0;
    padding-top: 40px;
    &:hover,
    &:focus{
      outline: 0;
      box-shadow: none; 
    }
    &:focus{
      padding-top: 10px;
    }
    @include placeholder(){
      color: #676447;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      text-align-last: center;
    }
  }

  .form-actions{
    text-align: center;
  }

  input[type="submit"]{
    width: 256px;
    height: 50px;
    background-color: #4cabca;
    border-radius: 25px;
    box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
    color: white;
    font-family: $FuturaLight;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    border:0;
  }
  
  .form-item{
    margin-bottom: 40px;
  }

  .webform-component--group{
    padding-left: 20px;
    padding-right: 20px;
    > .panel-body{
      display: flex;
      flex-direction:row;
      justify-content:space-between;
      &::before,
      &::after{
        content: none;
      }
    }
  }

  .webform-component--group--imya,
  .webform-component--group--nomer-telefona,
  .webform-component--group--e-mail{
    width: 350px;
  }
  
  .webform-component--kommentariy{
    padding-left: 20px;
    padding-right: 20px;
  }

  .webform-component--top{
    margin-bottom: 6px;
    > .panel-body{
      display: flex;
      flex-direction:row;
      align-items:flex-start;
      &::before,
      &::after{
        content: none;
      }
    }
  }

  .webform-component--top--zagolovok{
    color: #676447;
    font-family: $FuturaLight;
    font-size: 30px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    margin: 10px 58px 0 20px;
    padding:0 0 0 20px;
    border-left: 5px solid #fcc82b;
    p{
      margin: 0;
    }
  }

  .webform-component--top--kontaktnaya-informaciya{
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    a{
      color: #202223;
    }
    p{
      margin: 0;
    }
  }

}

@include respond-to('medium'){
  .paragraphs-item-pb--tabs-set{
    margin-bottom: 80px;
    .ui-tabs{
      .ui-tabs-nav{
        justify-content: center;
      }
    } 
  } 

  .paragraphs-item-pb--packages-set{
    margin-bottom: 117px;
  }

  .paragraphs-item-pb--packages{
    &:not(:last-child){
      margin-bottom: 60px;
    }
    >.content{
      display: block;
      .slick{
        margin-right: 30px;
        float: left;
      }
      .group-right{
        width: initial; 
        padding-left: 386px;
      }
    }
  }

  body.node-type-m-machine{
    .node-m-machine{
      .yellow-block{
        min-height: initial;
        padding-top: 70px;
        padding-bottom: 80px;
        margin-top: 0px;
        background: url(../images/machine/bg__yellow-block-mob.jpg) center center repeat;
        .links{
          justify-content: space-around;
          > *{
            &:not(:last-child){
              margin-bottom: 25px;
            }
          }
        }
      }
    } 
    .bg-grey{
      padding: 80px 0 84px 0;
      /*margin-bottom: 80px;*/
    }
    .paragraphs-item-pb--our-awards{
      margin-bottom: 50px;
    }
  } 

  .paragraphs-item-pb--video{
    margin-bottom: 80px;
  }
  
  .paragraphs-item-pb--reviews-machine{
    .group-wrap{
      >.field-name-field-pbrm-reviews{
        .view-v-reviews{
          .view-header{
            margin-bottom: 50px;
          }
        } 
      } 
    }
  } 
  
  .pane-webform-client-block-158{
    padding-top: 80px;
    padding-bottom: 143px;
  }

  #webform-client-form-158{
    .webform-component--top--zagolovok{
      margin: 10px 20px 0 0px;
    }
    .webform-component--group{
      padding-left: 0px;
      padding-right: 0px;
      .panel-body{
        flex-wrap:wrap;
        > *{
          &:last-child{
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .webform-component--kommentariy{
      padding-left: 0px;
      padding-right: 0px;
    }
  } 

}

@include respond-to('small'){

  .paragraphs-item-pb--tabs-set{
    margin-bottom: 74px;
    .ui-tabs{
      .ui-tabs-nav{
        justify-content: center;
      }
    } 
    .field-name-field-description{
      padding: 0 5px;
      ul{
        li{
          padding: 0 0 0 0px;
        }
      } 
    }
  } 

  .paragraphs-item-pb--packages-set{
    margin-bottom: 80px;
  }

  .paragraphs-item-pb--packages{
    &:not(:last-child){
      margin-bottom: 60px;
    }
    >.content{
      display: block;
      .slick{
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 30px;
        width: 100%;
        max-width: 356px;
        .slick__slider{
          .slick-list{
            .slick-slide{
              img{
                max-width: 100%;
                height: auto;
              }
            } 
          } 
        } 
        .slick__arrow{
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .slick-arrow{
            position: static;
            &:not(:last-child){
              margin-right: 5px;
            }
          }
        }
      }
      .group-right{
        width: initial;
      }
    }
  }

  body.node-type-m-machine{
    .node-m-machine{
      .big-image{
        padding-bottom: 49px;
        > .bi__container{
          position: relative;
        }
        .node-title-wrap{
          margin: 33px 0 0 0px;
          padding: 0 0 0 10px;
        }
        .field-name-field-formatted-title{
          font-size: 22px;
          line-height: 1.2;
        }
        .field-name-field-mm-after-title{
          margin: 27px 0 0 0px;
        }
        .field-name-field-mm-gallery{
          margin: 35px 0 0 0px;
          .field-items{
            margin-left: auto;
            margin-right: auto;
          }
        }
        .field-name-field-mm-big-img{
          .field-item{
            background:none!important;
            img{
              opacity: 1;
              height: auto;
            }
          }
        }
      }
      .yellow-block{
        min-height: initial;
        padding-top: 72px;
        padding-bottom: 80px;
        margin-top: 0px;
        background: url(../images/machine/bg__yellow-block-mob.jpg) center center repeat;
        .links{
          flex-direction: column;
          justify-content: flex-start;
          align-items:center;
          > *{
            margin-right: 0px;
            &:not(:last-child){
              margin-right: 0px;
              margin-bottom: 20px;
            }
          }
        }
        .field-name-body{
          text-align: center;
          margin-bottom: 54px;
        }
      }
    } 
    .bg-grey{
      padding: 80px 0 84px 0;
      /*margin-bottom: 80px;*/
    }
    .paragraphs-item-pb--our-awards{
      margin-bottom: 50px;
    }
  } 

  .paragraphs-item-pb--video{
    margin-bottom: 80px;
    .field-name-field-pbv-logo{
      margin-top: 30px;
    }
    h1 {
      font-size: 24px;
    }
    .field-name-field-description{
      font-size: 20px;
      padding: 0 9px;
      line-height: 1.2;
      margin-top: 37px;
    }
    .video-link{
      margin-top: 32px;
    }
  }
  
  .paragraphs-item-pb--reviews-machine{
    .group-wrap{
      > .field-name-field-title{
        border-left: 0;
        text-align: center;
        .field-items{
          &::after{
            content: none;
          }
          .field-item{
            padding: 0 0px 0 20px;
            border-left: 5px solid #fcc82b;
          }
        }
      }
      >.field-name-field-pbrm-reviews{
        margin-top: 27px;
        .view-v-reviews{
          .view-header{
            margin-bottom: 58px;
            justify-content: center;
            #block-multiblock-4{
              padding-left: 0px;
            }
          }
          .view-content{
            .views-row{
              .node-m-reviews{
                .circle{
                  
                }
                .field-name-body{
                  
                }
                .video{
                  
                }
                .field-name-field-image{
                  
                }
              } 
            } 
          } 
        } 
      } 
    }
  } 
  
  .pane-webform-client-block-158{
    padding-top: 0px;
    padding-bottom: 102px;
  }

  #webform-client-form-158{
    .form-item{
      margin-bottom: 20px;
    }

    .webform-component--top--zagolovok{
      margin: 0px 0px 20px 0px;
    }
    .webform-component--top{
      >.panel-body{
        flex-direction: column;
        align-items:center;
        justify-content:flex-start;
      }
    } 
    .webform-component--group{
      padding-left: 0px;
      padding-right: 0px;
      .panel-body{
        flex-direction: column;
        align-items:center;
        justify-content:flex-start;
        > *{
          &:last-child{
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .webform-component--group--imya, 
    .webform-component--group--nomer-telefona, 
    .webform-component--group--e-mail{
      max-width: 350px;
      width: 100%;
    }

    .webform-component--kommentariy{
      padding-left: 0px;
      padding-right: 0px;
    }
  } 

}