//Параграфы для статей
.paragraphs-item-pb--articles,
.paragraphs-item-pb--recomended-articles{
	position: relative;
	> .content{
		> .field-name-field-image{
			.field-item{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-position: center top;
				background-size: cover;
			}
		}
	}
	.group-wrap{
		padding-top: 152px;
		padding-bottom: 130px;
		position: relative;
		@include respond-to('small'){
			padding-top: 76px;
			padding-bottom: 66px;
		}
		.field-name-field-title-decor{
			margin-bottom: 37px;
			@include respond-to('small'){
				margin-bottom: 19px;
			}
		}
		.field-name-field-pba-articles{
			margin-bottom: 47px;
			@include respond-to('small'){
				margin-bottom: 36px;
			}
		}
		.field-name-field-after{
			text-align: center;
			a{
				color: #5d5747;
				font-family: $FuturaLight;
				font-size: 17px;
				font-weight: 400;
				text-decoration: underline;
				text-transform: uppercase;
			}
		}
	}
}
//Вьюха для параграфов вышей)
.view-v-article-ins{
	.view-content{
		@include respond-to('large'){
			display: flex;
			flex-direction:row;
			justify-content:space-between;
		}
		.views-row{
			@include respond-to('large'){
				width: 552px;
			}
			.node-article{
				display: flex;
				flex-direction:row;
				@include respond-to('tablet_mobile'){
					flex-direction:column;
					align-items:center;
				}
				&:hover{
					.node-title{
						a{
							color: #4cabca;
						}
					}
					.node-detail{
						a{
							background-color: #4cabca;
						}
					}
				}
				.col-1{
					@include respond-to('large'){
						width: 255px;
						margin-right: 30px;
					}
					margin-top: 3px;
				}
				.col-2{
					@include respond-to('large'){
						width: calc(100% - 283px);
					}
					@include respond-to('tablet_mobile'){
						text-align: center;
						padding-top: 28px;
					}
				}
				.field-name-field-image{
					a{
						display: block;
					}
				}
				.node-title{
					margin-bottom: 10px;
					a{
						color: #767d39;
						font-family: $FuturaLight;
						font-size: 16px;
						font-weight: 400;
						text-transform: uppercase;
						line-height: 1.2;
					}
				}
				.field-name-body{
					color: #202223;
					font-size: 14px;
					font-weight: 300;
					line-height: 1.28;
					margin-bottom: 36px;
					@include respond-to('small'){
						margin-bottom: 39px;
						padding: 0 5px;
					}
				}
				.node-detail{
					a{
						display: inline-flex;
						flex-direction:row;
						justify-content:center;
						align-items:center;
						width: 163px;
						height: 50px;
						background-color: #fcc82b;
						border-radius: 25px;
						color: white;
						font-family: $FuturaLight;
						font-size: 14px;
						font-weight: 400;
						text-transform: uppercase;
					}
				}
			}
		}
		.slick-list{
			.slick-slide{
				font-size: 0;
			}
		}
		.slick-arrow{
			position: absolute;
			top: 95px;
			width: 19px;
			height: 19px;
			border-radius: 100%;
			font-size: 0;
			text-indent: -9999px;
			background-color: transparent;
			background-repeat: no-repeat;
			border:0;
		}
		.slick-prev{
			left: 0;
			background-image: url('../images/slick-arr-yellow-left.png');
		}
		.slick-next{
			right: 0;
			background-image: url('../images/slick-arr-yellow-right.png');
		}
	}
}

body.node-type-article{

	.node-article.node-full{
		background: url('../images/node-grey-bg.png') center top no-repeat;
		padding-top: 130px;
		padding-bottom: 77px;
		> .node__container{

		}
		.two-col{
			display: flex;
			flex-direction:row;
			.field-name-field-image{
				width: 255px;
				margin-right: 50px;
				margin-top: 6px;
			}
			.col-2{
				width: calc(100% - 305px);
			}
		}
		.node-title{
			color: #767d39;
			font-family: $FuturaLight;
			font-size: 20px;
			font-weight: 400;
			text-transform: uppercase;
			margin-bottom: 26px;
		}
		.node-created{
			font-size: 14px;
			color: #fcc82b;
			font-weight: 700;
			margin-bottom: 24px;
		}
		.field-name-body{
			font-size: 14px;
			line-height: 22px;
			color: #202223;
			font-weight: 300;
			padding-bottom: 35px;
			p{
				margin: 0 0 22px 0;
			}
		}
		.group_img_lbl{
			position: relative;
			margin-bottom: 55px;
		}
	
		.field-name-field-article-big-img{
			.field-item{
				background-position: center top;
				background-size: cover;
				img{
					height: auto;
				}
			}
		}

		.field-name-field-article-big-img-lbl{
			position: absolute;
			top: 60px;
			left: 60px;
			color: white;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.24;
			border-left: 5px solid #fcc82b;
			padding: 0 0 3px 20px;
		    p{
		    	margin: 0;
		    }
		}
		.field-name-field-article-body2{
			color: #202223;
			font-size: 14px;
			font-weight: 300;
			line-height: 22px;
			p{
				margin: 0 0 56px 0;
			}
		}
	}
	
	.paragraphs-item-pb--recomended-articles{
		.group-wrap{
			padding-bottom: 181px;
		}
	}

}

@include respond-to('medium'){
	body.node-type-article{
		.node-article.node-full{
			padding-top: 110px;
			.two-col{
				display: block;
				.field-name-field-image{
					margin-right: 25px;
					float: left;
					margin-bottom: 25px;
					width: initial;
				}
				.col-2{
					width: initial;
				}
			} 
			.field-name-field-article-body2{
				p{
					margin: 0 0 41px 0;
				}
			} 
		} 
		.paragraphs-item-pb--recomended-articles{
			.group-wrap{
				padding-top: 110px;
				padding-bottom: 130px;
			}
		} 
	} 
}

@include respond-to('small'){

	body.node-type-article{
		.node-article.node-full{
			padding-top: 90px;
			padding-bottom: 50px;
			.two-col{
				flex-direction: column;
				align-items:center;
				.field-name-field-image{
					margin-top: 0px;
					margin-right: 0px;
					margin-bottom: 30px;
					width: initial;
				}
				.col-2{
					width: 100%;
					text-align: center;
				}
			} 
			.field-name-field-article-body2{
				text-align: center;
				p.image-group{
					margin: 0 0 41px 0;
				}
				.list-group{
					text-align: left;
				}
			} 
			.group_img_lbl{
				margin-bottom: 40px;
			}
			.field-name-field-article-big-img-lbl{
				margin-top: 22px;
				position: static;
				padding: 0 0 3px 10px;
				color: #202223;
			}
		} 
		.paragraphs-item-pb--recomended-articles{
			.group-wrap{
				padding-bottom: 66px;
			}
		} 
	} 

}

body.page-articles{

	#main{
		background: url('../images/node-grey-bg.png') center top no-repeat;
		padding-top: 137px;
	}
	
	.view-v-article{
		margin-top: -47px;
		margin-bottom: 131px;
	}

}

.view-v-article{
	
	.view-filters{
		margin-bottom: 87px;
		.form-control.jq-selectbox{
			margin-left: auto;
		}
	}

	.view-content{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:space-between;
		.views-row{
			width: 552px;
			margin-bottom: 86px;
			.node-article{
				display: flex;
				flex-direction:row;
				&:hover{
					.node-title{
						a{
							color: #4cabca;
						}
					}
					.node-detail{
						a{
							background-color: #4cabca;
						}
					}
				}
				.col-1{
					width: 255px;
					margin-right: 30px;
					margin-top: 3px;
				}
				.col-2{
					width: calc(100% - 283px);
				}
				.field-name-field-image{
					.field-item{
						background: none!important;
					}
					a{
						display: block;
					}
				}
				.node-title{
					margin-bottom: 10px;
					a{
						color: #767d39;
						font-family: $FuturaLight;
						font-size: 16px;
						font-weight: 400;
						text-transform: uppercase;
						line-height: 1.2;
					}
				}
				.field-name-body{
					color: #202223;
					font-size: 14px;
					font-weight: 300;
					line-height: 1.28;
					margin-bottom: 36px;
				}
				.node-detail{
					a{
						display: inline-flex;
						flex-direction:row;
						justify-content:center;
						align-items:center;
						width: 163px;
						height: 50px;
						background-color: #fcc82b;
						border-radius: 25px;
						color: white;
						font-family: $FuturaLight;
						font-size: 14px;
						font-weight: 400;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}

@include respond-to('medium'){
	.view-v-article{
		.view-content{
			justify-content:space-around;
			.views-row{
				width: 267px;
				.node-article{
					flex-direction:column;
					.col-1{
						margin: 0 0 30px 0;
						width: initial;
					}
					.col-2{
						width: initial;
					}
				}
			}
		} 
	} 
}

@include respond-to('small'){
	body.page-articles{
		#main{
			padding-top: 90px;
		}
		.view-v-article{
			margin-top: 47px;
			margin-bottom: 90px;
		}
	} 
	.view-v-article{
		.view-filters{
			margin-bottom: 47px;
			.views-exposed-widgets{
				justify-content: center;
				>*:not(:last-child){
					margin-right: 0px;
					width: 100%;
				}
			}
			.form-control.jq-selectbox{
				width: 100%;
				max-width: 356px;
			}
		}
		.view-content{
			justify-content:center;
			.views-row{
				width: 100%;
				margin-bottom: 50px;
				.node-article{
					flex-direction:column;
					align-items:center;
					.col-1{
						margin: 0 0 0px 0;
						width: initial;
					}
					.col-2{
						width: initial;
						text-align: center;
						padding-top: 28px;
						.field-name-body{
							margin-bottom: 39px;
							padding: 0 5px;
						}
					}
				}
			}
		} 
	} 
}