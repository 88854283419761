.front .paragraphs-item-pb--our-product-set{
	.content{
		background-size: cover;
	}
}
.paragraphs-items-field-mf-carousel{
	@include respond-to('large'){
		margin-top: -30px;
	}
	.slick-list{
		.slick-slide{

		}
	}
	.slick-dots{
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		position: absolute;
		bottom: 93px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction:row;
		font-size: 0;
		@include respond-to('small'){
			bottom: auto;
			top: 151px;
		}
		li{
			list-style: none;
			&:not(:last-child){
				margin-right: 11px;
			}
			button{
				margin: 0 0 0 0;
				padding: 0 0 0 0;
				border:0;
				font-size: 0;
				text-indent: -9999px;
				width: 9px;
				height: 9px;
				border-radius: 100%;
				background-color: #fcc82b;
			}
			&.slick-active{
				button{
					background-color: #4cabca;
				}
			}
		}
	}
}
.paragraphs-items-field-mf-paragraphs {
	margin-top: -50px;
}

.paragraphs-item-pb--carousel-img-txt{
	position: relative;
	@include respond-to('small'){
		background: url(../images/mobile/mobile_front_intro-blk-bi-bg.png) center top/cover no-repeat;
	}
	.field-name-field-pbcit-image{
		.field-item{
			background-position: center top;
			background-size: cover;
			img{
				height: 100%;
				@include respond-to('medium'){
					height: 650px;
				}
				@include respond-to('small'){
					height:auto;
				}
			}
		}
	}
	.group-wrap{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding-top: 171px;
		@media (min-width: 1280px) and (max-width: 1440px) {
	    padding-top: 70px;
		}
		@include respond-to('medium'){
			padding-top: 85px;
		}
		@include respond-to('small'){
			position: relative;
			padding-top: 47px;
			padding-bottom: 42px;
		}
	}
	.field-name-field-pbcit-content{
		.bii__txt1{
			color: #5d5747;
			font-family: $FuturaLight;
			font-size: 17px;
			font-weight: 400;
			line-height: 1.3;
			text-transform: uppercase;
			border-left: 5px solid #fcc82b;
			padding: 0 0 0 12px;
			margin: 0px 0 18px 12px;
			@include respond-to('small'){
				font-size: 15px;
				line-height: 1.5;
				padding: 0 0 0 13px;
				margin: 0px 0 18px 0px;
			}
		}
		.bii__title{
			color: white;
			font-family: $FuturaLight;
			font-size: 98px;
			font-weight: 400;
			line-height: 1;
			margin: 0 0 41px 0px;
			padding: 0 0 0 7px;
			@media (min-width: 1280px) and (max-width: 1440px) {
				font-size: 70px;
			}
			@include respond-to('small'){
				color: #fcc82b;
			    font-size: 60px;
			    padding: 0 0 0 0px;
			    margin: 0 0 19px 0px;
			}
		}
		.medals{
			width: 270px;
			margin: 0 0 0 9px;
			font-size: 0;
			display: inline-flex;
			flex-direction:row;
			flex-wrap: wrap;
			justify-content:center;
			@include respond-to('small'){
				margin: 0 auto 0 auto;
				display: flex;
			}
			.medal{
				&:not(:last-child):not(:nth-child(2n+2)){
					margin-right: 34px;
				}
				display: flex;
				flex-direction:column;
				align-items:center;
				text-align: center;
				margin-bottom: 18px;
				.m-img{
					margin-bottom: 15px;
				}
				.m-txt{
					color: #5d5747;
					font-family: 'Times New Roman';
					font-weight: 400;
					line-height: 1;
					text-transform: uppercase;
					font-size: 15px;
					span{
						font-size: 26px;
					}
				}
			}
		}
	}
}


.node-m-front{
	> .content{
		> .field-name-body{
			background: url('../images/front__two-col-bg.png') center top no-repeat;
			min-height: 446px;
			padding-top: 152px;
			margin-bottom: 71px;
			@include respond-to('large'){
				margin-top: -79px;
			}
			@include respond-to('medium'){
				padding-top: 100px;
				margin-top: -75px;
				margin-bottom: 0px;
			}
			> .field-items{
				@include make-container();
			}
			.two-col{
				display: flex;
				flex-direction:row;
				.tc{
					color: #202223;
					font-size: 14px;
					font-weight: 300;
					line-height: 1.58;
					width: 50%;
					&:first-child{
						text-align: right;
						padding: 0 32px 0 24px;
					}
					&:last-child{
						text-align: left;
						padding: 0 22px 0 33px;
					}
				}
			}
			@include respond-to('small'){
				min-height: initial;
				background-size: cover;
				padding-top: 72px;
				padding-bottom: 181px;
				margin-bottom: 0px;
				.two-col{
					flex-direction:column;
					.tc{
						width: 100%;
						text-align: center!important;
						padding: 0 10px 0 10px!important;
					}
				}
			}
		}
	}
}

.front__intro-block{
	margin-bottom: 71px;
	@include respond-to('large'){
		margin-top: -30px;
	}
	@include respond-to('small'){
		margin-bottom: 0px;
	}
	.big-image{
		position: relative;
		@include respond-to('small'){
			background: url('../images/mobile/mobile_front_intro-blk-bi-bg.png') center top / cover no-repeat;
		}
		.bi__img{
			background-repeat: no-repeat;
			background-position: center top;
			background-size: cover;
			img{
				max-width: 100%;
				height: 100%;
				opacity: 0;
				@include respond-to('medium'){
					height: 650px;
				}
				@include respond-to('small'){
					height:auto;
				}
			}
		}
		.bi__container{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			padding-top: 171px;
			@include respond-to('medium'){
				padding-top: 85px;
			}
			@include respond-to('small'){
				position: relative;
				padding-top: 47px;
				padding-bottom: 42px;
			}
		}

		.bii__txt1{
			color: #5d5747;
			font-family: $FuturaLight;
			font-size: 17px;
			font-weight: 400;
			line-height: 1.3;
			text-transform: uppercase;
			border-left: 5px solid #fcc82b;
			padding: 0 0 0 12px;
			margin: 0px 0 18px 12px;
			@include respond-to('small'){
				font-size: 15px;
				line-height: 1.5;
				padding: 0 0 0 13px;
				margin: 0px 0 18px 0px;
			}
		}
		.bii__title{
			color: white;
			font-family: $FuturaLight;
			font-size: 98px;
			font-weight: 400;
			line-height: 1;
			margin: 0 0 41px 0px;
			padding: 0 0 0 7px;

			@include respond-to('small'){
				color: #fcc82b;
			    font-size: 60px;
			    padding: 0 0 0 0px;
			    margin: 0 0 19px 0px;
			}
		}
		.medals{
			width: 270px;
			margin: 0 0 0 9px;
			font-size: 0;
			display: inline-flex;
			flex-direction:row;
			flex-wrap: wrap;
			justify-content:center;
			@include respond-to('small'){
				margin: 0 auto 0 auto;
				display: flex;
			}
			.medal{
				&:not(:last-child):not(:nth-child(2n+2)){
					margin-right: 34px;
				}
				display: flex;
				flex-direction:column;
				align-items:center;
				text-align: center;
				margin-bottom: 18px;
				.m-img{
					margin-bottom: 15px;
				}
				.m-txt{
					color: #5d5747;
					font-family: 'Times New Roman';
					font-weight: 400;
					line-height: 1;
					text-transform: uppercase;
					font-size: 15px;
					span{
						font-size: 26px;
					}
				}
			}
		}
		
	}

	.yellow-cell{
		background: url('../images/front__two-col-bg.png') center top no-repeat;
		min-height: 446px;
		padding-top: 152px;
		@include respond-to('large'){
			margin-top: -75px;
		}
		@include respond-to('medium'){
			padding-top: 100px;
			margin-top: -75px;
		}
		> .tc__container{

		}
		.two-col{
			display: flex;
			flex-direction:row;
			.tc{
				color: #202223;
				font-size: 14px;
				font-weight: 300;
				line-height: 1.58;
				width: 50%;
				&:first-child{
					text-align: right;
					padding: 0 32px 0 24px;
				}
				&:last-child{
					text-align: left;
					padding: 0 22px 0 33px;
				}
			}
		}
		@include respond-to('small'){
			min-height: initial;
			background-size: cover;
			padding-top: 72px;
			padding-bottom: 181px;
			.two-col{
				flex-direction:column;
				.tc{
					width: 100%;
					text-align: center!important;
					padding: 0 10px 0 10px!important;
				}
			}
		}
	}

}

.field-name-field-title-decor{
	text-align: center;
	font-size: 0;
	.field-item{
		display: inline-block;
		color: #606568;
		font-family: $FuturaLight;
		font-size: 30px;
		font-weight: 400;
		text-transform: uppercase;
		padding: 0 25px;
		position: relative;
		&::before{
			content: '';
			position: absolute;
			top: -3px;
			bottom: 0;
			margin: auto 0;
			left: 0px;
			width: 12px;
			height: 12px;
			background: url('../images/title-decor.png') no-repeat;
		}
		&::after{
			content: '';
			position: absolute;
			top: -3px;
			bottom: 0;
			margin: auto 0;
			right: 0px;
			width: 12px;
			height: 12px;
			background: url('../images/title-decor.png') no-repeat;
		}
	}
	@include respond-to('small'){
		.field-item{
			font-size: 20px;
			padding: 0 21px;
			margin: 0 10px;
		}
	}
}

.paragraphs-item-pb--our-product-set{
	position: relative;
	overflow: hidden;
	margin-bottom: 117px;
	@include respond-to('small'){
		margin-top: -28px;
		margin-bottom: 75px;
	}
	> .content{
		>.field-name-field-image{
			.field-item{
				background-position: center top;
				background-size: cover;
				@include respond-to('tablet_mobile'){
					position: absolute;
					top:0;
					left: 0;
					right: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.group-wrap{
		@include respond-to('large'){
			// position: absolute;
			// top:0;
			// left: 0;
			// right: 0; 
		}
		@include respond-to('tablet_mobile'){
			position: relative;
			padding-bottom: 40px;
		}
		@include respond-to('small'){
			padding-left: 0px;
			padding-right: 0px;
			padding-bottom: 49px;
		}
		> .field-name-field-title-decor{
			margin-bottom: 41px;
			@include respond-to('small'){
				margin-bottom: 24px;
			}
		}
		> .field-name-field-description{
			color: #202223;
			font-size: 14px;
			font-weight: 300;
			line-height: 1.58;
			text-align: center;
			margin-bottom: 50px;
			@include respond-to('small'){
				padding: 0 23px;
				margin-bottom: 44px;
			}
		}
		.paragraphs-items-field-pbops-products{
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content:center;
			.paragraphs-item-pb--our-product-blk{
				margin-bottom: 40px;
				width: 380px;
				@include respond-to('large'){
					&:not(:nth-child(3n+3)):not(:last-child){
						margin-right: 15px;
					}
				}
				@include respond-to('small'){
					width: 100%;
					max-width: 380px;
					margin-bottom: 30px;
				}
			}
		}
	}
}

.paragraphs-item-pb--our-product-blk{
	position: relative;
	&:hover{
		.field-name-field-image{
			.field-item{
				&::after{
					content: '';
				}
			}
		}
		.field-name-field-link{
			a{
				background-color: #4cabca;
			}
		}
	}
	.field-name-field-image{
		font-size: 0;
		.field-item{
			display: inline-block;
			background: none!important;
			position: relative;
			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url('../images/image__white-trafaret.png') center top / cover no-repeat;
				z-index: 3;
			}
			&::after{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(33,108,132,.7);
			}
			img{
				opacity: 1;
				@include respond-to('small'){
					height: auto;
				}
			}
		}
	}
	.field-name-field-title{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin-top: -25px;
		left: 0;
		width: 100%;
		color: white;
		font-family: $FuturaLight;
		font-size: 17px;
		font-weight: 400;
		line-height: 1.2;
		text-transform: uppercase;
		text-align: center;
		@include respond-to('small'){
			padding: 0 9px;
			margin-top: -23px;
		}
	}
	.field-name-field-link{
		margin-top: -25px;
		position: relative;
		z-index: 4;
		a{
			display: flex;
			flex-direction:row;
			justify-content:center;
			align-items:center;
			width: 163px;
			height: 50px;
			background-color: #fcc82b;
			border-radius: 25px;
			box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
			color: white;
			font-family: $FuturaLight;
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
			margin: 0 auto 0 auto;
		}
	}
}

.paragraphs-item-pb--adv-set{
	margin-bottom: 49px;
	@include respond-to('small'){
		margin-bottom: 31px;
	}
	>.content{
		> .field-name-field-title-decor{
			margin-bottom: 52px;
			@include respond-to('small'){
				margin-bottom: 43px;
			}
		}
		.paragraphs-items-field-pbas-adv{
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content: center;
			.paragraphs-item-pb--adv-block{
				width: 20%;
				margin-bottom: 57px;
				@include respond-to('medium'){
					width: calc(100% / 2);
				}
				@include respond-to('small'){
					width: 100%;
					margin-bottom: 37px;
				}
			}
		}
	}
}

.paragraphs-item-pb--adv-block{
	text-align: center;
	.field-name-field-image{
		min-height: 94px;
		.field-item{
			background: none!important;
			img{
				opacity: 1;
			}
		}
	}
	.field-name-field-title{
		color: #767d39;
		font-family: $FuturaLight;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
		min-height: 59px;
	}
	.field-name-field-description{
		color: #202223;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.28;
	}
}

.paragraphs-item-pb--solut-farm-ins{
	position: relative;
	margin-bottom: 82px;
	@include respond-to('small'){
		margin-bottom: 47px;
	}
	.field-name-field-image{
		display: none;
		.field-item{
			background-position: center top;
			position: relative;
			background-size: cover;
			@include respond-to('tablet_mobile'){
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
			@include respond-to('large'){
				&::before{
					content: '';
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 45px;
					background: url('../images/trafaret__solut-farm-front__top.png') center top no-repeat;
				}
				&::after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0px;
					width: 100%;
					height: 45px;
					background: url('../images/trafaret__solut-farm-front__bottom.png') center top no-repeat;
				}
			}
		}
	}
	.field-name-field-video-bg {
		position: relative;
		min-height: 847px;
		.iframe_video_wrap {
		    overflow: hidden;
		    position: absolute;
		    left: 0px;
		    top: 0px;
		    width: 100%;
		    height: 100%;
		}
		.iframe_video {
		    height: calc(100% + 10px);
		    height: calc(100% + 10px);
		    margin: -5px;
		    position: relative;
		    filter: blur(5px);
		    &::after {
		    	content: '';
		    	width: 100%;
		    	height: 100%;
		    	position: absolute;
		    	left: 0px;
		    	top:0px;
		    	background: rgba(0,0,0,0.2); 
		    }
		    iframe {
			    position: absolute;
			    left: 0;
			    top: 0;
			    width: 100%;
			    height: 100%;
			}
		}
		@include respond-to('large'){
			&::before{
				content: '';
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 45px;
				background: url('../images/trafaret__solut-farm-front__top.png') center top no-repeat;
				z-index: 1;
			}
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0px;
				width: 100%;
				height: 45px;
				background: url('../images/trafaret__solut-farm-front__bottom.png') center top no-repeat;
				z-index: 1;
			}
		}
		@include respond-to('tablet_mobile'){
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}
	.group-wrap{
		padding-top: 182px;
		@include respond-to('large'){
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
		}
		@include respond-to('medium'){
			padding-top: 130px;
			padding-bottom: 80px;
			position: relative;
		}
		@include respond-to('small'){
			padding-top: 75px;
			position: relative;
			padding-bottom: 80px;
		}
		> .field-name-field-title-decor{
			margin-bottom: 55px;
			@include respond-to('small'){
				margin-bottom: 48px;
			}
			.field-item{
				color: white;
			}
		}
	}
}

.view-v-solut-farm{
	.view-content{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:space-between;
		@include respond-to('medium'){
			justify-content: space-around;
		}
		@include respond-to('small'){
			justify-content: center;
		}
		.views-row{
			width: 265px;
			@include respond-to('tablet_mobile'){
				&:not(:last-child){
					margin-bottom: 67px;
				}
			}
			.node-m-solut-farm{
				text-align: center;
				.node-title{
					margin-bottom: 25px;
					a{
						color: white;
						font-family: $FuturaLight;
						font-size: 16px;
						font-weight: 400;
						text-transform: uppercase;
						line-height: 1.14;
					}
				}
				.field-name-field-msf-point{
					margin-bottom: 24px;
					img{
						&:not(:last-child){
							margin-right: 11px;
						}
					}
				}
				.field-name-body{
					color: white;
					font-size: 14px;
					font-weight: 300;
					line-height: 1.22;
					margin-bottom: 26px;
					p{
						margin: 0;
					}
				}
				.node-detail{
					a{
						display: inline-flex;
						flex-direction:row;
						justify-content:center;
						align-items:center;
						width: 163px;
						height: 50px;
						border: 1px solid #fdb92c;
						border-radius: 25px;
						color: white;
						font-family: $FuturaLight;
						font-size: 14px;
						font-weight: 400;
						text-transform: uppercase;
						&:hover{
							background-color: #4cabca;
							border-color: #4cabca;
						}
					}
				}
			}
		}
	}
}

#front-set-1{
	background: url('../images/bg__front-set-1.png') top center no-repeat;
	padding-top: 28px;
	padding-bottom: 100px;
	@include respond-to('small'){
		padding-bottom: 79px;
	}
}

.paragraphs-item-pb--become-partner{
	text-align: center;
	margin-bottom: 114px;
	@include respond-to('small'){
		margin-bottom: 76px;
	}
	.group-wrap{

	}
	.field-name-field-title-decor{
		margin-bottom: 21px;
		@include respond-to('small'){
			margin-bottom: 24px;
		}
	}
	.field-name-field-description{
		color: #202223;
		font-size: 14px;
		font-weight: 300;
		line-height: 22px;
		margin-bottom: 34px;
		@include respond-to('small'){
			padding: 0 9px;
		}
	}
	.field-name-field-link{
		a{
			display: inline-flex;
			flex-direction:row;
			justify-content:center;
			align-items:center;
			color: white;
			font-family: $FuturaLight;
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
			width: 163px;
			height: 50px;
			background-color: #fcc82b;
			border-radius: 25px;
		}
	}
}

.paragraphs-item-pb--plant-figures-set{
	margin-bottom: 120px;
	@include respond-to('small'){
		margin-bottom: 46px;
	}
	.group-wrap{

	}
	.field-name-field-title-decor {
		margin-bottom: 44px;
		@include respond-to('small'){
			margin-bottom: 22px;
		}
	}
	.paragraphs-items-field-pbpfs-numeral{
		display: flex;
		flex-direction:row;
		justify-content:space-between;
		@include respond-to('tablet_mobile'){
			flex-wrap:wrap;
		}
		@include respond-to('medium') {
			justify-content:space-around;
		}
		@include respond-to('small') {
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		.paragraphs-item-pb--plant-figures-blk{
			min-width: 202px;
			@include respond-to('small') {
				min-width: 252px;
				margin-bottom: 25px;
			}
		}
	}
}

.paragraphs-item-pb--plant-figures-blk{
	text-align: center;
	.field-name-field-title{
		color: #4cabca;
		font-family: $FuturaBlack;
		font-size: 90px;
		font-weight: 400;
		text-transform: uppercase;
		line-height: 1;
		position: relative;
		margin-bottom: 17px;
		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 20px;
			background-color: #fcc82b;
		}
	}
	.field-name-field-description{
		color: #202223;
		font-size: 14px;
		font-weight: 300;
		text-transform: uppercase;
		line-height: 1.28;
	}
}

.btn__yellow-style-link{
	display: inline-flex;
	flex-direction:row;
	justify-content:center;
	align-items:center;
	width: 183px;
	height: 50px;
	background-color: #fcc82b;
	border-radius: 25px;
	color: white;
	font-family: $FuturaLight;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	&:hover {
		color: #fff;
	}
}

.view-v-our-awards{
	.view-content{
		@include respond-to('large'){
			display: flex;
			flex-direction:row;
			justify-content:space-between;
		}
		.views-row{
			@include respond-to('large'){
				width: 260px;
			}
			.node-m-our-awards{
				display: flex;
				flex-direction:column;
				align-items:center;
				.field-name-field-image{
					margin-bottom: 25px;
				}
				.field-name-body{
					color: #202223;
					font-size: 14px;
					font-weight: 300;
					text-align: center;
					line-height: 1.28;
					@include respond-to('small'){
						padding: 0 13px;
					}
				}
			}
		}
		.slick-list{
			.slick-slide{
				font-size: 0;
			}
		}
		.slick-arrow{
			position: absolute;
			top: 85px;
			width: 19px;
			height: 19px;
			border-radius: 100%;
			font-size: 0;
			text-indent: -9999px;
			background-color: transparent;
			background-repeat: no-repeat;
			border:0;
		}
		.slick-prev{
			left: 0;
			background-image: url('../images/slick-arr-yellow-left.png');
		}
		.slick-next{
			right: 0;
			background-image: url('../images/slick-arr-yellow-right.png');
		}
	}
}

.paragraphs-item-pb--our-awards{
	.group-wrap{

	}
	.field-name-field-title-decor{
		margin-bottom: 32px;
		@include respond-to('small'){
			margin-bottom: 22px;
		}
	}
	.field-name-field-pboa-awards{
		margin-bottom: 41px;
		@include respond-to('small'){
			margin-bottom: 26px;
		}
	}
	.field-name-field-after{
		text-align: center;
	}
}

.front {
	.paragraphs-item-pb--our-product-set {
		.group-wrap {
			@include respond-to('tablet_mobile'){
				width: auto;
				&>.field-name-field-description {
					max-width: 728px;
					margin: 0 auto;
				}
				.paragraphs-item-pb--our-product-blk:nth-child(2n-1) {
					margin-right: 15px;
				}
			}
			@media (max-width: 780px) {
				.paragraphs-item-pb--our-product-blk:nth-child(2n-1) {
					margin-right: 0px;
				}
			}
		}
	} 
}

