body.calc-effict-type{
	.page-header {
		h1.title {
			display: none;
		}
	}
	#content{
		padding-bottom: 168px;
		padding-top: 103px;
		background-color: #ececec;
		.panel-col{
			> div{
				@include make-container();
			}
		}
	}

}

.ce-title{
	color: #676447;
	font-family: $FuturaLight;
	font-size: 30px;
	font-weight: 400;
	line-height: 1;
	text-transform: uppercase;
	border-left: 5px solid #fcc82b;
	padding: 0 0 0 19px;
	min-height: 36px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 40px;
	p{
		margin: 0;
	}
}

.ce-intro,
.ce-outro{
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
}

.ce-intro{
	margin-bottom: 74px;
}

.ce-outro{
	margin-top: 84px;
}

#calc-effict-form{
	input[type="text"]{
		width: 150px;
		height: 60px;
		background-color: white;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		color: black;
		font-family: $FuturaLight;
		font-size: 15px;
		font-weight: 400;
		text-align: center;
		@include placeholder(){
			color: black;
			font-family: $FuturaLight;
			font-size: 15px;
			font-weight: 400;
		}
	}

	.form-item{
		margin-bottom: 50px;
		> label{
			color: #202223;
			font-size: 14px;
			font-weight: 300;
			line-height: 22px;
			margin: 0 0 0 0;
			padding: 0 0 0 0;
		}
	}

	.form-type-select{
		display: flex;
		flex-direction:row;
		justify-content:center;
		align-items:center;
		> label{
			width: 583px;
			color: #676447;
			font-family: $FuturaLight;
			font-size: 30px;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			border-left: 5px solid #fcc82b;
			padding: 0 0 0 20px;
			margin-right: 55px;
			min-height: 36px;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 5px;
		}
	}

	fieldset{
		margin-bottom: 0px;
	    background-color: transparent;
	    border: 0;
	    border-radius: 0;
	    box-shadow: none;
		> .panel-body{
			padding: 0;
		}
	}

	input[type="submit"]{
		display: none;
	}

	.form-type-textfield{
		display: flex;
		flex-direction:row;
		align-items:center;
		> label{
			margin-right: 30px;
		}
	}
	
	#intro-group{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 70px;
	}
	
	#ajax-wrapper-culture{

		fieldset{
			margin-bottom: 90px;
		}
		
		.form-type-select{
			margin-bottom: 40px;
		}

		.harv-wrap{
			background-color: #e0e0e0;
			padding: 90px 87px 16px 47px;
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
			> *{
				&:nth-child(3){
					width: 100%;
				}
				&:nth-child(2),
				&:nth-child(5){
					margin-left: auto;
				}
			}
			.form-type-textfield{
				> label{
					text-align: right;
					width: 305px;
				}
			}
		}

	}

	.form-item-add-culture{
		margin-bottom: 40px;
	}
	
	.go-calc-effict{
		display: flex;
		flex-direction:row;
		justify-content:center;
		align-items:center;
		text-align: center;
		cursor: pointer;
		width: 286px;
		height: 60px;
		background-color: #4cabca;
		box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
		color: white;
		font-family: $FuturaLight;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
	}
	
	.form-item-cost-storage-harvest,
	.go-calc-effict{
		margin-bottom: 100px;
	}

	.clear-all{
		display: flex;
		flex-direction:row;
		justify-content:center;
		align-items:center;
		text-align: center;
		cursor: pointer;
		width: 286px;
		height: 60px;
		background-color: #9c0f17;
		box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
		color: white;
		font-family: $FuturaLight;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
	}

	#edit-group-result{
		background-color: #e0e0e0;
		padding: 90px 90px 40px 96px;
		.form-type-textfield{
			margin-right: 60px;
			> label{
				width: 452px;
				text-align: right;
			}
		}
		> .panel-body{
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
		}
	}
	
	.validation-error{
		border: 1px solid red!important;
	}
	
	.dynamic-currency{
		text-transform: lowercase;
	}

}

@include respond-to('medium'){
	body.calc-effict-type{
		#content{
			padding-top: 80px;
			padding-bottom: 120px;
		}
	} 
	
	.ce-intro{
		margin-bottom: 54px;
	}

	#calc-effict-form{
		#intro-group{
			justify-content: center;
			margin-bottom: 50px;
			> *:nth-child(1){
				margin-right: auto;
			}
			> *:nth-child(2){
				margin-left: auto;
			}
			.form-type-textfield{
				> label{
					min-width: 325px;
				}
			}
		}

		.form-type-select{
			> label{
				width: calc(100% - 386px);
				margin-right: 30px;
			}
		}

		#ajax-wrapper-culture{
			
			fieldset{
				margin-bottom: 70px;
			}

			.harv-wrap{
				padding-top: 70px;
				justify-content: center;
				> *{
					width: 100%;
					&:nth-child(2),
					&:nth-child(5){
						margin-left: initial;
					}
				}
				.form-type-textfield{
					> label{
						width: 363px;
					}
				}
			}

		}

		#edit-group-result{
			padding: 90px 87px 66px 47px;
			> .panel-body{
				justify-content: center;
				.form-type-textfield{
					width: 100%;
					margin-right: 0px;
					> label{
						width: 363px;
					}
				}
			}
		}

		.form-item-cost-storage-harvest, 
		.go-calc-effict{
			margin-bottom: 50px;
		}

	} 

	.ce-outro{
		margin-top: 64px;
	}
}

@include respond-to('small'){
	body.calc-effict-type{
		#content{
			padding-top: 80px;
			padding-bottom: 120px;
		}
	} 

	.ce-title{
		padding: 0px 0 0 10px;
		font-size: 24px;
	}
	
	.ce-intro{
		margin-bottom: 54px;
	}

	#calc-effict-form{

		.form-control.jq-selectbox{
			max-width: 356px;
			width: 100%;
		}

		#intro-group{
			justify-content: center;
			margin-bottom: 30px;
			.form-type-textfield{
				> label{
					
				}
			}
		}

		.form-item{
			margin-bottom: 30px;
		}

		.form-type-select{
			width: 100%;
			flex-direction: column;
			justify-content: flex-start;
			> label{
				width: 100%;
				margin-right: 0px;
				margin-bottom: 30px;
				text-align: center;
				font-size: 24px;
				padding: 0 0 0 10px;
			}
		}

		.form-type-textfield{
			width: 100%;
			flex-direction: column;
			justify-content: flex-start;
			> label{
				width: 100%;
				margin-right: 0px;
				margin-bottom: 30px;
				text-align: center;
			}
		}

		#ajax-wrapper-culture{
			
			fieldset{
				margin-bottom: 50px;
			}

			.harv-wrap{
				padding: 60px 10px 16px 10px;
				justify-content: center;
				> *{
					width: 100%;
					&:nth-child(2),
					&:nth-child(5){
						margin-left: initial;
					}
				}
				.form-type-textfield{
					> label{
						width: 100%;
						text-align: center;
					}
				}
			}

		}

		#edit-group-result{
			padding: 60px 10px 60px 10px;
			> .panel-body{
				justify-content: center;
				.form-type-textfield{
					width: 100%;
					margin-right: 0px;
					> label{
						width: 100%;
						text-align: center;
					}
				}
			}
		}

		.form-item-cost-storage-harvest, 
		.go-calc-effict{
			margin-bottom: 50px;
		}

	} 

	.ce-outro{
		margin-top: 64px;
	}
}