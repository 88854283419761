// Page Layout
// -----------------------------------------------------------------------------
#page-wrapper,
#all-wrapper{
	width: 100%;
	position: relative;
	overflow: hidden;
}
img{
	max-width: 100% !important;
	height: auto;   
}

a{
	text-decoration: none;
	outline: none!important;
	&:hover,
	&:focus{
		text-decoration: none;
		outline: none!important;
	}
}

*:focus{
	outline: 0;
}

.field-type-image{
	.field-item{
		background-repeat: no-repeat;
		> img{
			opacity: 0;
			max-width: 100%;
		}
	}
}

.owl-carousel .owl-item img{
	width: initial;
}

.ajax-progress-throbber { display: none}

.page-user{
	#main #content{
		@include make-container();
		min-height: 100vh;
	}
}
#main {
	min-height: 100px;
}
.container{
	@include respond-to('small'){
		padding-left: 10px;
		padding-right: 10px;
	}
}

#breadcrumb{
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 5.4px 5.9px 8px rgba(0, 0, 0, 0.12);
	> .b__container{
		min-height: 30px;
	}
	.breadcrumb{
		padding: 6px 0 0 8px;
		margin-bottom: 0px;
		background-color: transparent;
		border-radius: 0px;
		font-size: 13px;
		font-weight: 600;
		> .inline{
			color: #4cabca;
			padding: 0 5px 0 0;
			a{
				color: #5d5747;
				&:hover{
					color: #4cabca;
				}
			}
		}
		> .delimiter{
			color: #fcc82b;
			padding: 0 7px 0 0;
		}
	}
}

.list-group{
	display: flex;
	flex-direction:row;
	> * {
		width: 50%;
	}
	@include respond-to('small'){
		flex-direction:column;
		> * {
			width: 100%;
			&:not(:last-child){
				margin-bottom: 30px;
			}
		}
	}
}

.image-group{
	display: flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content:space-between;
	@include respond-to('medium'){
		justify-content:space-around;
		> *{
			margin-bottom: 15px;
		}
	}
	@include respond-to('small'){
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		> *{
			margin-bottom: 15px;
		}
	}
}

.field{

	ul{
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		li{
			list-style: none;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			position: relative;
			padding: 0 0 0 10px;
			@include respond-to('small'){
				padding: 0 0 0 3px;
			}
			&::before{
				content: '';
				position: absolute;
				top: 8px;
				left: 0px;
				width: 5px;
				height: 5px;
				border-radius: 100%;
				background-color: #fcc82b;
				@include respond-to('small'){
					position: relative;
					top: 3px;
					content: '.';
				    display: inline-block;
				    font-size: 0;
				    text-indent: -9999px;
				    margin-right: 4px;
				}
			}
		}
	}

	ol{
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		counter-reset: item;
		li{
			list-style: none;
			position: relative;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			padding: 0 0 0 12px;
			&:before{
				position: absolute;
				top: 0px;
				left: 0px;
				content: counter(item);
				counter-increment: item;
				color: #fcc82b;
			}
		}
	}
	
}

.views-exposed-widgets {
    margin-bottom: 0px;
}

.views-exposed-form{
	.views-exposed-widget{
		float: none;
		padding: 0 0 0 0;
	}
} 

.page-header{
	border-bottom: 0;
	margin: 0 0 0 9px;
	padding: 0 0 0 19px;
	border-left: 5px solid #fcc82b;
	@include respond-to('small'){
		margin: 0 0 0 0px;
		padding: 0 0 0 10px;
	}
	h1.title{
		color: #676447;
		font-family: $FuturaLight;
		font-size: 30px;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		margin: 0 0 0 0;
		@include respond-to('small'){
			font-size: 24px;
		}
	}
}


//Для мобилок, когда блок перестраивается в слайдер(шаблон)
/*.slick-list{
  .slick-slide{
    font-size: 0;
  }
}
.slick-arrow{
  position: absolute;
  top: 85px;
  width: 19px;
  height: 19px;
  border-radius: 100%;
  font-size: 0;
  text-indent: -9999px;
  background-color: transparent;
  background-repeat: no-repeat;
  border:0;
}
.slick-prev{
  left: 0;
  background-image: url('../images/slick-arr-yellow-left.png');
}
.slick-next{
  right: 0;
  background-image: url('../images/slick-arr-yellow-right.png');
}*/

* {
	outline: none!important;
}

.silver-modal-content {
	overflow: hidden;
}

#main {
	#page-header {
		.tabs {
			position: relative;
			z-index: 5;
		}
	}
}

#cboxClose {
	position: absolute;
    top: 0px!important;
    right: 0px!important;
    width: 31px!important;
    height: 31px!important;
    cursor: pointer;
    float: none;
    font-size: 0;
    text-shadow: none;
    opacity: 1;
    background: url(../images/modals__close.png) no-repeat!important;
    z-index: 20;
}

.characteristics {
  margin-top: 22px;
  width: 680px;
  @include respond-to('small') {
  	width: 100%;
  }
	td {
		color: #484848;
		font-size: 14px;
		font-weight: 400;
		border-right: 2px solid white;
		width: 50%;
		padding: 2px 15px;
		@include respond-to('small') {
			display: block;
			width: 100%;
    	}
	}
  	.title {
  		color: white;
		font-size: 14px;
		font-weight: 700;
  		background-color: #51431b;
  		padding: 5px 15px!important;
  	}
  	tr:nth-child(2n) {
	    td {
    		&:nth-child(2n) {
    			background-color: #f0f0f0;
    			@include respond-to('small') {
	    			background-color: white;
	    		}
    		}
    		&:nth-child(2n-1) {
    			background-color: #f6f2e5;
    		}
	    }
  	}
  	tr:nth-child(2n-1) {
	    td {
    		&:nth-child(2n) {
    			background-color: none;
    		}
    		&:nth-child(2n-1) {
    			background-color: none;
    			@include respond-to('small') {
	    			background-color: #f0f0f0;
	    			&.title {
				    	background-color: #51431b;
				    }
	    		}

    		}
	    }

  	}
}