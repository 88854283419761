.page-contacts {
  #breadcrumb {
    display: none;
  }
  .pane-block-8,.pane-1 {
    .google-maps-contacts {
      position: relative;
      .block-for-container {
        position: absolute;
        top: 0;
        width: 100%;
        .container {
          width: 1170px;
          position: relative;
          height: 0px;
          .adress-container {
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.9);
            box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
            border-left: 8px solid #facb3e;
            position: absolute;
            margin-top: 19%;
            @include respond-to('medium') {
              margin-top: 40%;
            }
            @include respond-to('small') {
              margin-top: 25%;
              width: 271px;
            }
            .adress {
              padding: 0 35px 35px 35px;
              @include respond-to('medium') {
                padding: 0 35px 5px 35px;
              }
              @include respond-to('small') {
                padding: 0 35px 5px 35px;
              }
              span {
                  color: #767d39;
                  font-family: "FuturaFuturisLightC";
                  font-size: 20px;
                  font-weight: 700;
                  text-transform: uppercase;
                  padding-top: 40px;
                  display: block;
                  padding-bottom: 10px;
                  @include respond-to('small') {
                    padding-top: 10px;
                    padding-bottom: 0px;
                  }
              }
              p {
                  margin: 0;
                  color: #202223;
                  font-size: 16px;
                  line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
  .pane-webform-client-block-615, .pane-webform-client-block-618 {
    background-image: url(../images/dealers/bg_webform.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: relative;
    padding-top: 145px;
    margin-bottom: 65px;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      width: 100%;
      height: 45px;
      background: url(../images/trafaret__solut-farm-front__bottom.png) center top no-repeat;
    }
    #webform-client-form-615, #webform-client-form-618 {
      @include make-container();
    }
    #webform-client-form-615, #webform-client-form-618 {
      .webform-component-fieldset {
        margin-bottom: 0px;
        background-color: transparent;
        border: 0;
        border-radius: 0px;
        box-shadow: none;
        > .panel-body{
          padding: 0;
        }
      }
      input[type="text"],
      input[type="email"]{
        width: 100%;
        height: 60px;
        background-color: white;
        border-radius: 0px;   
        color: #676447;
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        box-shadow: none;
        border:0;
        padding: 0 0 0 30px;
        &:hover,
        &:focus{
          outline: 0;
          box-shadow: none;
        }
        @include placeholder() {
          color: #000000;
          font-size: 15px;
          font-weight: 400;
        }
      }
      textarea{
        width: 100%;
        height: 120px;
        background-color: white;
        border-radius: 0px;
        color: #676447;
        font-size: 14px;
        font-weight: 300;
        resize:none;
        text-align: left;
        box-shadow: none;
        border:0;
        padding-top: 50px;
        &:hover,
        &:focus{
          outline: 0;
          box-shadow: none; 
        }
        &:focus{
          padding-top: 10px;
        }
        @include placeholder() {
          color: #000000;
          font-size: 15px;
          font-weight: 400;
          text-align: center;
          text-align-last: center;
        }
      }
      .form-actions {
        text-align: center;
        margin-bottom: 180px;
      }
      .grippie {
        display: none;
      }
      input[type="submit"] {
        width: 180px;
        height: 60px;
        background-color: #4cabca;
        box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
        color: white;
        font-family: $FuturaLight;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        border:0;
        border-radius: 0;
      }
      .form-item{
        margin-bottom: 50px;
        @include respond-to('small') {
          margin-bottom: 20px;
        }
      }
      .webform-component--wrapper {
        > .panel-body{
          display: flex;
          flex-direction:row;
          justify-content:space-between;
            @include respond-to('medium') {
              display: block;
            }
            @include respond-to('small') {
              display: block;
            }
          &::before,
          &::after{
            content: none;
          }
        }
      }
      .webform-component--wrapper--name,
      .webform-component--wrapper--phone-namber,
      .webform-component--wrapper--e-mail {
        width: 356px;
        @include respond-to('medium') {
          width: 100%;
        }
        @include respond-to('small') {
          width: 100%;
        }
      }
      .webform-component--zagolovok {
        color: white;
        font-family: "FuturaFuturisLightC";
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 44px;
        .title-webform {
          margin: 0;
          position: relative;
          display: inline-block;
          &::before {
            content: '';
            position: absolute;
            top: -3px;
            bottom: 0;
            margin: auto 0;
            left: -30px;
            width: 12px;
            height: 12px;
            background: url(../images/title-decor.png) no-repeat;
          }
          &::after {
            content: '';
            position: absolute;
            top: -3px;
            bottom: 0;
            margin: auto 0;
            right: -30px;
            width: 12px;
            height: 12px;
            background: url(../images/title-decor.png) no-repeat;
          }
        }
      }
    }
  }
  .pane-block-9, .pane-2  {
    .block-for-contacts {
      p {
        color: #facb3e;
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        @include respond-to('small') {
          font-size: 23px;
          font-weight: 700;
        }
      }
      .wrap {
        display: flex;
        justify-content: start;
        padding-top: 5px;
        @include respond-to('small') {
          flex-wrap: wrap;
          padding-top: 10px;
        }
        .name {
          width: 250px;
          padding-left: 96px;
          color: #767d39;
          font-size: 16px;
          font-weight: 700;
          text-align: right;
          @include respond-to('medium') {
            padding-left: 0px;
          }
          @include respond-to('small') {
            width: 100%;
            text-align: left;
            padding-left: 20px;
          }
        }
        .description {
          padding-left: 36px;
          font-size: 15px;
          line-height: 22px;
          @include respond-to('medium') {
            width: 450px;
          }
          @include respond-to('small') {
            width: 100%;
            padding-left: 20px;
          }
          p {
            color: black;
            font-size: 15px;
            text-align: left;
            text-transform: none;
          }
        }
      }
    }
    .filialy {
      padding-top: 50px;
      padding-bottom: 110px;
      p {
        color: #facb3e;
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        @include respond-to('small') {
          font-size: 23px;
          font-weight: 700;
        }
      }
      .kazakstan, .moldova, .romania, .lytva {
        padding-top: 20px;
        .wrap {
          display: flex;
          justify-content: start;
          padding-top: 5px;
          @include respond-to('small') {
            padding-top: 30px;
          }
          @include respond-to('small') {
            flex-wrap: wrap;
            padding-top: 0px;
          }
          .name {
            width: 250px;
            padding-left: 96px;
            color: #767d39;
            font-size: 16px;
            font-weight: 700;
            text-align: right;
            @include respond-to('small') {
              width: 100%;
              text-align: left;
              padding-left: 20px;
            }
          }
          .description {
            padding-left: 36px;
            font-size: 15px;
            line-height: 22px;
            @include respond-to('medium') {
              width: 450px;
            }
            @include respond-to('small') {
              width: 100%;
              padding-left: 20px;
            }
            p {
              color: black;
              font-size: 15px;
              text-align: left;
              text-transform: none;
            }
            span {
              color: #4cabca;
              padding-right: 10px;
              font-size: 15px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}