.item-list{
	ul.pager{
		font-size: 0;
		margin: 32px 0 32px 0;
		padding: 0;
		li{
			display: inline-flex;
			margin: 0 7px;
			vertical-align: top;
			a{
				display: block;
				&:hover{
					background-color: transparent;
				}
			}
		}

		.pager-item,
		.pager-current{
			align-items:center;
			*{
				padding: 0;
				border-radius: 0;
				border:0;
				background-color: transparent;
				float: none;
				margin: 0;
				line-height: 1;
				text-transform: uppercase;
				font-family: $FuturaLight;
				font-size: 15px;
				font-weight: 400;
				color: #202223;
				&:hover{
					color: #4cabca;
				}
			}
		}

		.pager-ellipsis{
			align-items:center;
			font-size: 15px;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
		}
		
		.pager-current{
			*{
				color: #4cabca;
			}
		}

		.pager-first,
		.pager-last{
			display: none;
		}
		.pager-previous{
			margin: 0 29px 0 0;
			a{
				
			}
		}
		.pager-next{
			margin: 0 0px 0 29px;
			a{
				
			}
		}
		.pager-previous,
		.pager-next{
			a{
				float: none;
				padding: 0;
				margin: 0;
				border:0;
				border-radius: 0;
				font-family: $FuturaLight;
				font-size: 15px;
				font-weight: 400;
				line-height: 12px;
				text-transform: uppercase;
				color: #202223;
				background: none;
				&:hover{
					border-bottom: 1px solid #4cabca;
					color: #4cabca;
					//text-decoration: underline;
				} 
			}
		}
	}
}

@include respond-to('medium'){
	.item-list{
		ul.pager{
			
		}
	} 
}

@include respond-to('small'){
	.item-list{
		ul.pager{
			.pager-next{
				//margin-top: 15px;
			}
		}
	}
}