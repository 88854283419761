#footer{
	background: url('../images/bg__footer.png') center top no-repeat;
	position: relative;
	z-index: 30;
	&::before{
		content: '';
		position: absolute;
		top:-44px;
		left: 0;
		width: 100%;
		height: 44px;
		z-index: 32;
		background: url('../images/trafaret__footer.png') center top no-repeat;
		@include respond-to('small'){
			content: none;
		}
	}
	> .f__container{
		min-height: 500px;
		padding-top: 80px;
		@include respond-to('tablet_mobile'){
			min-height: 287px;
			padding-top: 60px;
		}
	}
	.region-footer{

	}
	#block-multiblock-1{
		text-align: center;
		margin-bottom: 51px;
		@include respond-to('tablet_mobile'){
			display: none;
		}
		a.ctools-use-modal{
			display: inline-flex;
			flex-direction:row;
			justify-content:center;
			align-items:center;
			width: 230px;
			height: 40px;
			background-color: #fcc82b;
			border-radius: 20px;
			color: white;
			font-family: $FuturaLight;
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
		}
	}
	#block-block-3{
		color: white;
		font-size: 16px;
		line-height: 1.875;
		font-weight: 300;
		margin-bottom: 43px;
		@include respond-to('tablet_mobile'){
			display: none;
		}
		a{
			font-weight: 700;
			color: white;
			text-decoration: none;
		}
		.two-col{
			display: flex;
			flex-direction:row;
			justify-content:center;
			p{
				margin: 0;
			}
			.col{
				&:not(:last-child){
					margin-right: 95px;
				}
			}
		}
	}
	.block-menu-menu-social-menu-instance,
	#block-block-6{
		margin-bottom: 35px;
		.block__content{
			> ul{
				padding: 0 0 0 0;
				margin: 0 0 0 0;
				display: flex;
				flex-direction:row;
				flex-wrap:wrap;
				justify-content: center;
				&::before,
				&::after{
					content: none;
				}
				> li {
					background: none!important;
					&:not(:last-child){
						margin-right: 4px;
					}
					> a{
						font-size: 0;
						text-indent: -9999px;
						padding: 0 0 0 0;
						background-color: transparent;
						display: flex;
						flex-direction:row;
						justify-content:center;
						align-items:center;
						width: 40px;
						height: 40px;
						border: 1px solid white;
						border-radius: 20px;
						> img{
							
						}
						.icon-item{
							display: flex;
						}
						&:hover{
							background-color: #fcc82b;
							border-color: #fcc82b;
						}
					}
				}
			}
		}
	}
	#block-block-4{
		text-align: center;
		margin-bottom: 41px;
		a{
			color: white;
			font-size: 16px;
			font-weight: 700;
			line-height: 30px;
			text-decoration: underline;
		}
	}
	#block-multiblock-3{
		.lang-switch-group{
			justify-content: center;
			position: relative;
			.lang__cur,
			.lang__label{
				color: white;
			}
			.lang__icon{
				background: url('../images/icon__flat-world-yellow.png') no-repeat;
			}
			.lang__list {
				left: 50%;
			    margin-left: -72px;
			    bottom: 21px;
			    top: auto;
			}
		}
	}
}