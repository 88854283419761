body.page-our-awards{
  
  #main-wrapper{
    background-color: #ececec;
  }

  .big-image{
    position: relative;
    margin-bottom: 115px;
    .bi__image{
      background-repeat: no-repeat;
      background-position: center top;
      img{
        opacity: 0;
        max-width: 100%;
        height: 100%;
      }
    }
    > .bi__container{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .bi__title{
      text-align: center;
      font-size: 0;
      margin-top: 228px;
      padding-left: 5px;
      margin-bottom: 33px;
      .title-inner{
        display: inline-block;
        color: white;
        font-family: $FuturaLight;
        font-size: 40px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        padding-left: 19px;
        padding-top: 5px;
        border-left: 5px solid #fcc82b;
      }
    }
    .bi__body{
      color: white;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
    }
  }
  
  .page-title{
    .page-inner{
      color: #676447;
      font-family: $FuturaLight;
      font-size: 30px;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      padding-left: 20px;
        padding-top: 17px;
        padding-bottom: 13px;
      border-left: 5px solid #fcc82b;
    }
  }

  .view-v-our-awards-inner{
    margin-top: -59px;
    margin-bottom: 83px;
  }

}

.view-v-our-awards-inner{

  .view-filters{
    margin-bottom: 19px;
    .views-exposed-widgets{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .view-content{
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    @include respond-to('large') {
      justify-content: flex-start;
      .views-row {
        margin-right: 50px;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      // &:after {
      //   content: "";
      //   flex: auto;
      // }
    }
    .views-row{
      width: 254px;
      position: relative;
      padding-top: 40px;
      padding-bottom: 32px;
      &:hover{
        background-color: white;
        //box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
        &::before{
          content: '';
          position: absolute;
          top: 0;
          left: -37px;
          width: calc(100% + 74px);
          height: 100%;
          background-color: white;
          box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
        }
        /*.views-field-body {
          color: blue;
          text-decoration: underline;
        }*/
        .views-field-body {
          color: #4babc9; // text color
          text-decoration: underline;
        }
      }
      .views-field-field-image{
        margin-bottom: 25px;
        text-align: center;
        position: relative;
      }
      .views-field-body{
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        position: relative;
        line-height: 1.24;
        p{
          margin: 0;
        }
      }
    }
  }

  .item-list{
    ul.pager{
      margin: 89px 0 89px 0;
    }
  } 

}

@include respond-to('medium'){
  body.page-our-awards{
    .big-image{
      margin-bottom: 75px;
      .bi__image{
        background-size: cover;
      }
    } 
    .view-v-our-awards-inner{
      margin-top: 42px;
    }
  } 

  .view-v-our-awards-inner{
    .view-filters{
      .views-exposed-widgets{
        justify-content:center;
      }
    } 
    .view-content{
      justify-content: space-around;
    }
  }
}

@include respond-to('small'){
  body.page-our-awards{
    .big-image{
      margin-bottom: 75px;
      .bi__title{
        margin-top: 200px;
        padding-left: 0px;
        .title-inner{
          font-size: 30px;
          padding-left: 10px;
        }
      }
      .bi__body{
        font-size: 15px;
      }
      .bi__image{
        background-size: cover;
      }
    } 

    .page-title{
      .page-inner{
        font-size: 20px;
        padding-left: 15px;
      }
    } 

    .view-v-our-awards-inner{
      margin-top: 42px;
    }
  } 

  .view-v-our-awards-inner{
    .view-filters{
      .views-exposed-widgets{
        justify-content:center;
        > * {
          width: 100%;
        }
        .views-submit-button{
          display: none;
        }
      }
      .form-control.jq-selectbox{
        width: 100%;
        max-width: 356px;
      }
    } 
    .view-content{
      justify-content: center;
    }
  }
}