#all-wrapper{
	&.aw-mm{
		right: 0;
		transition: all 0.3s linear;
	}
}

body{
	&.mm{
		#all-wrapper{
			right: 266px !important;
		}
		#mobile-menu{
			right: 0;
		}
	}
}

#mobile-menu{
	position: absolute;
	top: 0;
	right: -266px;
	width: 266px;
	height: 100%;
	background-color: #362b1b;
	transition:right 0.3s linear;
	z-index: 999;
	padding: 25px 0 0 0;
	@include respond-to('large'){
		display: none;
	}
	.region-mobile-menu{

	}

	.lang-switch-group{
		flex-wrap:wrap;
		justify-content:center;
		text-align: center;
		margin-bottom: 35px;
		.lang__cur{
			color: white;
			order: 3;
			width: 100%;
			margin-top: 6px;
		}
		.lang__label{
			color: white;
			order: 2;
		}
		.lang__icon{
			background: url(../images/icon__flat-world-yellow.png) no-repeat;
			order: 1;
			margin: 4px 0px 0 0px;
		}
		.lang__list {
			position: absolute;
		    left: 50%;
		    top: 100%;
		    background-color: #ffffff;
		    z-index: 10;
		    margin-left: -72px;
		}
	} 

	.block-menu-menu-social-menu-instance,
	#block-block-7{
		margin: 0 0 0 0;
		.block__content{
			> ul{
				padding: 0 0 0 0;
				margin: 0 auto 29px auto;
				display: flex;
				flex-direction:row;
				flex-wrap:wrap;
				justify-content: center;
				width: 104px;
				&::before,
				&::after{
					content: none;
				}
				> li {
					background: none!important;
					margin-bottom: 11px;
					&:not(:last-child):not(:nth-child(3n+3)){
						margin-right: 10px;
					}
					> a{
						font-size: 0;
						text-indent: -9999px;
						padding: 0 0 0 0;
						background-color: transparent;
						display: flex;
						flex-direction:row;
						justify-content:center;
						align-items:center;
						width: 28px;
						height: 28px;
						background-color: #685943;
						border-radius: 14px;
						> img{
							
						}
						.icon-item{
							display: flex;
						}
						&:hover{
							background-color: #d2d3d4;
						}
					}
				}
			}
		}
	}

	.calc-effict{
		margin-bottom: 38px;
		a{
			display: flex;
			flex-direction:row;
			justify-content:center;
			align-items:center;
			width: 220px;
			height: 39px;
			border: 1px solid #fdb92c;
			border-radius: 20px;
			color: white;
			font-family: $FuturaLight;
			font-size: 13px;
			font-weight: 400;
			text-transform: uppercase;
			margin: 0 auto 0 auto;
		}
	}

	.block-menu-menu-support-menu-instance,
	.block-system-main-menu-instance{
		.block__content{
			> ul{
				margin: 0 0 0 0;
				padding: 0 30px 0 41px;
				> li{
					list-style: none;
					margin-bottom: 30px;
					> a, > span{
						color: white;
						font-family: $FuturaLight;
						font-size: 20px;
						font-weight: 400;
						line-height: 1;
						text-transform: uppercase;
						padding: 0 0 0 0;
						&:hover{
							color: #fcc82b;
							background-color: transparent;
						}
						span{
							display: none;
						}
					}

					> ul{
						margin: 16px 0 1px 0;
						padding: 0 0 0 0;
						display: none;
						> li{
							list-style: none;
							margin-bottom: 16px;
							> a, > span{
								display: block;
								font-family: $FuturaLight;
								font-size: 13px;
								font-weight: 400;
								text-transform: uppercase;
								color: white;
								position: relative;
								text-indent: 7px;
								padding: 0 0 0 0;
								line-height: 15.9px;
								&::before{
									content: '';
									position: absolute;
									top: 5px;
									left: 0px;
									width: 3px;
									height: 3px;
									border-radius: 100%;
									background-color: #fcc82b;
								}
								.icon-item{
									display: none;
								}
								&:hover{
									color: #fcc82b;
									background-color: transparent;
								}
							}
						}
					}
					
				}
			}
		}
	}
	
	#block-multiblock-10{
		a.ctools-use-modal{
			display: flex;
			flex-direction:row;
			justify-content:center;
			align-items:center;
			width: 220px;
			height: 39px;
			border: 1px solid #fdb92c;
			border-radius: 20px;
			color: white;
			font-family: $FuturaLight;
			font-size: 13px;
			font-weight: 400;
			text-transform: uppercase;
			margin: 8px auto 0 auto;
		}
	}

}