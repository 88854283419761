// Mixins
// -----------------------------------------------------------------------------
@mixin placeholder() {
  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder           { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
}

@mixin make-container() {
  @include container-fixed();
  @media (max-width: $screen-xs-max ) {
    padding-left: 10px;
    padding-right: 10px;
  } 
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
  
}


@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (max-width: $screen-xs-max )  {
      @content;
    }
  }

  @else if $breakpoint == "medium" {
    @media (min-width: $screen-sm) and (max-width: $screen-md-max ) {
      @content;
    }
  }

  @else if $breakpoint == "large" {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
  @else if $breakpoint == "tablet_mobile" {
    @media (max-width: $screen-md-max ) {
      @content;
    }
  }
}

@include respond-to('large'){
}
@include respond-to('medium'){
}
@include respond-to('small'){
}

@include respond-to('tablet_mobile'){
}

//ft = from-to
@mixin breakpoint-ft($breakpoint1,$breakpoint2){
  @media screen and (min-width: $breakpoint1) and (max-width: $breakpoint2)  {
    @content;
  }
}
//f = from
@mixin breakpoint-f($breakpoint){
  @media screen and (min-width: $breakpoint){
    @content;
  }
}
//t = to
@mixin breakpoint-t($breakpoint){
  @media screen and (max-width: $breakpoint){
    @content;
  }
}
