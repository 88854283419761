body.node-type-m-solut-farm {
  .page-header {
    h1.title {
      display: none;
    }
  }
  .node-m-solut-farm {
    .big-image {
      position: relative;
      .field-name-field-msf-top-bg {
        .field-item{
          background-position: center top;
          @include respond-to('medium') {
            height: 450px;
            background-size: cover;
          }
          @include respond-to('small') {
            height: 450px;
            background-size: cover;
          }
        }
      }
      > .bi__container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
      .node-title {
        margin: 304px 0 40px 0px;
        text-align: center;
        @include respond-to('medium') {
          margin: 130px 0 20px 0px;
        }
        @include respond-to('small') {
          margin: 100px 0 20px 0px;
        }
        h1 {
          border-left: 5px solid #fcc82b;
          color: #fff;
          display: inline-block;
          font-family: $FuturaLight;
          font-size: 40px;
          font-weight: 400;
          line-height: 1;
          margin: 0;
          padding: 5px 10px 0 10px;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          @include respond-to('medium') {
            font-size: 30px;
          }
          @include respond-to('small') {
            font-size: 28px;
            margin: 0 30px;
          }
        }
      }
      .field-name-field-msf-text-top {
        color: #fff;
        font-size: 16px;
        line-height: 26px;
      }
    }
    
    .yellow-block {
      min-height: 473px;
      /*background: url('../images/dealers/bg__yellow-block.png') center top  no-repeat;*/
      background: url('../images/bg__yellow-block-new.png') center top  no-repeat;
      margin-top: -70px;
      z-index: 2;
      position: relative;
      padding-top: 121px;
      @include respond-to('small') {
        min-height: initial;
        background-size: cover;
        padding-top: 72px;
        padding-bottom: 104px;
        margin-bottom: 0px;
      }
      .field-name-field-msf-text-yellow-bg {
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        margin-bottom: 34px;
        text-align: center;
        p {
          margin: 0;
          &:not(:first-of-type) {
            margin-top: 22px;
          }
        }
        ul {
          li {
            font-weight: 300;
            &::before {
              background-color: white;
            }
          }
        } 
      }
    }
  }
  .paragraphs-item-pb--wrapper {
    margin-bottom: 114px;
  }
  .field-name-field-title-decor-big {
    color: #5d5747;
    font-size: 16px;
    text-transform: none;
    h3 {
      font-size: 30px;
      font-weight: normal;
    }
  }
  .paragraphs-item-pb--icon-text-template {
    .field-name-field-description {
      padding-bottom: 10px;
      ul {
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
  .paragraphs-item-pb--innova-decision {
    .group-wrap {
      &>.paragraphs-items-field-pbid-decisions {
        .paragraphs-item-pb--icon-text-template{
          &:nth-child(even) {
            &>.content {
              flex-direction: row;
              .field-name-field-image {
                margin: 0 52px 0 0;
              }
            }
          }
        }
      }
    }
  }
  .paragraphs-item-pb--machines {
    .item-list {
      display: none; 
    }
  }
}
.paragraphs-item-pb--decisions {
  background: url(../images/decision_bg.png) center bottom no-repeat;
  background-size: cover;
  margin-bottom: 40px;
  /*margin-top: -110px;*/
  padding: 114px 0 114px 0;
  > .content {
    @include make-container();
  }
}
.paragraphs-items-field-pb-decisions {
  counter-reset: machinecounter;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include respond-to('medium') {
    justify-content: center;
  }
  .paragraphs-item-pb--decision {
    counter-increment: machinecounter;
    margin-bottom: 30px;
    width: 550px;
    .group-top {
      box-shadow: 0 22px 22px rgba(0,0,0,0.12);
      position: relative;
      &:after {
        border: 2px solid #fcc82b;
        border-radius: 50%;
        content: counter(machinecounter);
        color: #fff;
        font-family: "FuturaFuturisLightC";
        font-size: 40px;
        left: 50%;
        height: 72px;
        line-height: 68px;
        margin-left: -36px;
        position: absolute;
        top: 60px;
        text-align: center;
        width: 72px;
      }
    }
  }
  .field-name-field-pb-decision-bg {
    position: relative;
    &:after {
      background: url(../images/decision_border.png) center top/cover no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3;
    }
  }
  .field-name-field-pb-decision-title {
    color: white;
    font-family: "FuturaFuturisLightC";
    font-size: 17px;
    font-weight: 400;
    left: 0;
    line-height: 1.2;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 154px;
    width: 100%;
  }
  .field-name-field-pb-decision-desc {
    color: #fff;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    padding: 30px 30px 30px 30px;
    text-align: center;
  }
}
.paragraphs-item-pb--machines {
  > .content {
    @include make-container();
  }
  .field-name-field-pb-machine-title {
    border-left: 5px solid #fcc82b;
    color: #676447;
    font-family: "FuturaFuturisLightC";
    font-size: 30px;
    margin-bottom: 60px;
    padding: 0 140px 0 15px;
    text-transform: uppercase;
    @include respond-to('small') {
      margin-bottom: 120px;
      padding-right: 0;
    }
  }
}
.paragraphs-item-pb--bnr {
  @include make-container();
  margin-bottom: 114px;
  .content {
    position: relative;
  }
  .field-name-field-pb-bnr-bg {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .field-items {
      height: 100%;
    }
    .field-item {
      background-size: cover;
      height: 100%;
    }
  }
  .field-name-field-pb-bnr-body {
    position: relative;
    z-index: 2;
    .field-item {
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      padding: 29px 78px;
      @include respond-to('medium') {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include respond-to('small') {
        padding-left: 10px;
        padding-right: 10px;
      }
      .yellow {
        color: #fcc82b;
      }
      .left {
        border-left: 5px solid #fcc82b;
        font-size: 169px;
        line-height: 125px;
        padding-left: 44px;
        width: 450px;
        @include respond-to('medium') {
          font-size: 120px;
          line-height: normal;
          width: 320px;
        }
        @include respond-to('small') {
          font-size: 120px;
          line-height: normal;
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .right {
        font-size: 21px;
        width: calc(100% - 450px);
        @include respond-to('medium') {
          width: calc(100% - 320px);
        }
        @include respond-to('small') {
          width: 100%;
        }
      }
    }
  }
}
.paragraphs-item-pb--certificates-licenses {
  margin-bottom: 114px;
  .field-name-field-title-decor {
    margin-bottom: 32px;
  }
  .field-name-field-pbcl {
    margin-bottom: 41px;
  }
  .field-name-field-after {
    text-align: center;
  }
}

.view-id-machines_eva {
  .slick {
    margin-left: -20px;
    position: relative;
    width: calc(100% + 40px);
    @include respond-to('small') {
      margin-left: 0;
      width: 100%;
    }
  }
  .slick-arrow {
    border: none;
    color: transparent;
    height: 46px;
    position: absolute;
    text-indent: -9999px;
    top: -100px;
    width: 46px;
    &.slick-disabled {
      opacity: 0.5;
    }
    &.slick-prev {
      background: url(../images/sl_arrow_left.png) center no-repeat;
      right: 75px;
      @include respond-to('small') {
        left: 0;
        right: auto;
      }
    }
    &.slick-next {
      background: url(../images/sl_arrow_right.png) center no-repeat;
      right: 20px;
      @include respond-to('small') {
        right: 0;
      }
    }
  }
  .slide__content {
    width: 356px;
    margin: 0 auto;
    @include respond-to('small') {
      width: 300px;
    }
    &:hover {
      box-shadow: 0 22px 22px rgba(0,0,0,0.12);
      .node-m-machine.node-teaser {
        .field-name-field-mm-img-preview {
          .field-item {
            img {
              box-shadow: none;
            }
          }
        }
        .field-name-field-formatted-title {
          border-color: #4cabca;
        }
        .node-detail {
          a {
            background-color: #4cabca;
          }
        }
      }
    }
    .node-m-machine.node-teaser {
      .field-name-field-mm-img-preview {
        overflow: hidden;
        .field-item {
          background: none!important;
          img {
            box-shadow: 0 12px 12px rgba(0, 0, 0, 0.07);
          }
        }
      }
      .bottom {
        height: 333px;
        overflow: hidden;
        background-color: white;
        display: flex;
        flex-direction:column;
        justify-content:flex-end;
        padding: 0px 0 0 49px;
        @include respond-to('small') {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .field-name-field-formatted-title { 
        align-items: center;
        border-left: 5px solid #fcc82b;
        display: flex;
        flex-direction: row;
        font-family: $FuturaLight;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        margin: 0 0 30px 0;
        min-height: 52px;
        height: 52px;
        overflow: hidden;
        padding: 0 0 0 10px;
        text-transform: uppercase;
        p {
          margin: 0;
        }
      }
      .field-name-field-mm-txt-preview {
        font-size: 14px;
        font-weight: 300;
        height: 132px;
        line-height: 22px;
        margin-bottom: 14px;
        overflow: hidden;
        p {
          margin: 0;
        }
      }
      .node-detail {
        text-align: right;
        font-size: 0;
        a {
          display: inline-block;
          width: 60px;
          height: 60px;
          background-color: #fcc82b;
          background-image: url('../images/arr_right.png');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}












