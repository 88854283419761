#modalContent{
  left: 0!important;
  width: 100%!important;
  .silver-modal-dialog{
    margin: 0 auto 0 auto;
    @include respond-to('medium') {
      width: 100%!important;
    }
    @include respond-to('small') {
      width: 100%!important;
    }
    .alert {
      display: none;
    }
    .silver-modal-content{
      box-shadow: none;
      background-color: transparent;
      border:0;
      border-radius: 0;
      .silver-close-modal{
        position: absolute;
        top: 137px;
        right: calc((100% - #{$container-lg}) / 2 );
        width: 31px;
        height: 31px;
        cursor: pointer;
        float: none;
          font-size: 0;
          text-shadow: none;
          opacity: 1;
          background: url('../images/modals__close.png') no-repeat;
          z-index: 20;
          @include respond-to('medium') {
            right: calc((100% - #{$container-md}) / 2 );
            top: 73px;
          }
          @include respond-to('small') {
            right: 10px;
            top: 53px;
            width: 23px;
            height: 23px;
            background-size: 100%;
          }
      }
      .silver-modal-body{
        padding: 0;
        width: 100%!important;
      }
    }
  }
  
  .webform-client-form{
    position: relative;
    > div{
      @include make-container();
      position: relative;
      z-index: 12;
      padding-top: 134px;
      padding-bottom: 158px;
      @include respond-to('medium') {
        padding-top: 70px;
        padding-bottom: 94px;
      }
      @include respond-to('small') {
        padding-top: 50px;
        padding-bottom: 74px;
      }
    }
    > video {
      z-index: 11;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  input[type="text"],
  input[type="email"]{
    width: 100%;
    height: 60px;
    background-color: white;
    color: black;
    font-family: $FuturaLight;
    font-size: 15px;
    font-weight: 400;
    padding: 0 0 0 30px;
    border:0;
    box-shadow: none;
    border-radius: 0;
    &:focus{
      outline: 0;
    }
    @include placeholder() {
      color: black;
      font-family: $FuturaLight;
      font-size: 15px;
      font-weight: 400;
    }
    &.error {
      border: 1px solid red;
    }
  }
  
  .form-actions{
    margin: 0;
    text-align: center;
  }

  input[type="submit"]{
    width: 178px;
    height: 50px;
    background-color: #fcc82b;
    border-radius: 25px;
    box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
    color: white;
    font-family: $FuturaLight;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    border:0;
    &:focus{
      outline: 0;
    }
  }

  textarea{
    width: 100%;
    height: 120px;
    background-color: white;
    color: black;
    font-family: $FuturaLight;
    font-size: 15px;
    font-weight: 400;
    padding: 50px 0 0 0;
    text-align: center;
    resize:none;
    border:0;
    box-shadow: none;
    border-radius: 0;
    &:focus{
      padding: 15px 0 0 30px;
      text-align: left;
      outline: 0;
    }
    @include placeholder() {
      color: black;
      font-family: $FuturaLight;
      font-size: 15px;
      font-weight: 400;
    }
    &.error {
      border: 1px solid red;
    }
  }

  fieldset{
    margin-bottom: 0px;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    > .panel-body {
      padding: 0;
    }
  }

  .form-item{
    margin-bottom: 50px;
    @include respond-to('small') {
      margin-bottom: 30px;
    }
  }

  .webform-component--zagolovok{
    color: #fffffe;
    font-family: $FuturaLight;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 75px;
    @include respond-to('small') {
      font-size: 30px;
      margin-bottom: 40px;
    }
    p{
      margin: 0;
    }
  }

  .webform-component--wrap{
    > .panel-body{
      display: flex;
      flex-direction:row;
      justify-content:space-between;
      &::before,
      &::after{
        content: none;
      }
      > *{
        width: 356px;
      }
      @include respond-to('medium') {
        flex-wrap:wrap;
        justify-content:space-around;
      }
      @include respond-to('small') {
        flex-direction: column;
        align-items:center;
        justify-content:flex-start;
        > *{
          width: 100%;
          max-width: 356px;
        }
      }
    }
  }

  .form-control.jq-selectbox{
    @include respond-to('small') {
      width: 100%;
      max-width: 356px;
    }
  }

}